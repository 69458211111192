<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page stats">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="filter-group row">
            <div class="col-6 col-sm-4">
              <select
                aria-label="연월일 선택"
                v-model="searchData.datetype"
              >
                <option value="year">연별</option>
                <option value="month">월별</option>
                <option value="day">일별</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <date-picker
                v-model="searchData.searchdate"
                valueType="format"
                :disabled="searchData.datetype == 'year'"
                :type="searchData.datetype == 'month' ? 'year' : 'month'"
                placeholder="날짜선택"
                aria-label="날짜선택"
                class="w-100"
              ></date-picker>
            </div>
          </div>

          <div class="card rounded-0 mb-4">
            <div
              class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
            >
              클럽별 매출
              <span class="align-self-center ms-auto price-unit"
                >(단위 : 천원)</span
              >
            </div>
            <div class="card-body">
              <GChart
                type="LineChart"
                :data="chartsData.saleclubData"
                :options="chartsData.saleclubtotalOptions"
              />
            </div>
          </div>

          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th sticky-column>클럽</b-th>
                <b-th
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'a' + key"
                >
                  {{ val[0] }}
                </b-th>
                <b-th> 합계 </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td sticky-column class="goods-kind">드라이버</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{
                    val[1]["드라이버"] &&
                    val[1]["드라이버"].salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="val[1]['드라이버']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum["드라이버"] &&
                    saleData.total_salesum["드라이버"].total_salesum
                      | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['드라이버']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">우드</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{ val[1]["우드"] && val[1]["우드"].salesum | comma("-") }}
                  <!-- <span
                    v-if="val[1]['우드']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum["우드"] &&
                    saleData.total_salesum["우드"].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['우드']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">유틸리티</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{
                    val[1]["유틸리티"] &&
                    val[1]["유틸리티"].salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="val[1]['유틸리티']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum["유틸리티"] &&
                    saleData.total_salesum["유틸리티"].total_salesum
                      | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['유틸리티']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">아이언</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{
                    val[1]["아이언"] && val[1]["아이언"].salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="val[1]['아이언']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum["아이언"] &&
                    saleData.total_salesum["아이언"].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['아이언']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">웨지</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{ val[1]["웨지"] && val[1]["웨지"].salesum | comma("-") }}
                  <!-- <span
                    v-if="val[1]['웨지']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum["웨지"] &&
                    saleData.total_salesum["웨지"].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['웨지']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
              <b-tr>
                <b-td sticky-column class="goods-kind">퍼터</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{ val[1]["퍼터"] && val[1]["퍼터"].salesum | comma("-") }}
                  <!-- <span
                    v-if="val[1]['퍼터']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum["퍼터"] &&
                    saleData.total_salesum["퍼터"].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['퍼터']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot>
              <b-tr>
                <b-td sticky-column>합계</b-td>
                <b-td
                  v-for="(val, key) in saleData.saleclubinfo"
                  :key="'b' + key"
                  class="text-price"
                >
                  {{
                    val[1]["월 합계"] &&
                    val[1]["월 합계"].month_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="val[1]['월 합계']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum["합계"] &&
                    saleData.total_salesum["합계"].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum['합계']"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/stats/statsmainTab";
import ax from "@/api/stats";
import "@/filter/common";
import { GChart } from "vue-google-charts/legacy";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";
export default {
  name: "StatsMain",
  components: {
    location,
    tab,
    GChart,
    DatePicker,
  },
  data() {
    return {
      searchData: {
        datetype:
          this.$route.query.datetype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .datetype) ||
          "month",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      chartsData: {
        saleclubData: [],
        saleclubtotalOptions: {
          chart: {
            title: "클럽별 매출",
          },
          legend: { position: "bottom" },
        },
      },
      saleData: { saleclubinfo: [], total_salesum: {} },
      view_hide: null,
    };
  },
  methods: {
    get_saleclublist() {
      (this.searchData.goodstype =
        this.$route.params.goodstype ||
        (this.$store.state.searchData[this.$route.matched[0].name] &&
          this.$store.state.searchData[this.$route.matched[0].name]
            .goodstype) ||
        1),
        this.$store.commit("setSearchData", {
          [this.$route.matched[0].name]: this.searchData,
        });

      ax.get_saleclublist(this.searchData, (flag, data = "") => {
        this.saleData.total_salesum = data.total_salesum;
        this.saleData.saleclubinfo = [];
        this.chartsData.saleclubData = [
          ["기간", "드라이버", "우드", "유틸리티", "아이언", "웨지", "퍼터"],
        ];
        Object.keys(data.saleclubinfo)
          .sort((a, b) => {
            return new Date(a) - new Date(b);
          })
          .forEach((k) => {
            let datekey = k;
            if (this.searchData.datetype === "year")
              datekey = parseInt(k.substring(k.length - 4, k.length)) + "년";
            if (this.searchData.datetype === "month")
              datekey = parseInt(k.substring(k.length - 2, k.length)) + "월";
            if (this.searchData.datetype === "day")
              datekey = parseInt(k.substring(k.length - 2, k.length)) + "일";

            this.chartsData.saleclubData.push([
              datekey,
              (data.saleclubinfo[k]["드라이버"] &&
                parseInt(data.saleclubinfo[k]["드라이버"].salesum)) ||
                0,
              (data.saleclubinfo[k]["우드"] &&
                parseInt(data.saleclubinfo[k]["우드"].salesum)) ||
                0,
              (data.saleclubinfo[k]["유틸리티"] &&
                parseInt(data.saleclubinfo[k]["유틸리티"].salesum)) ||
                0,
              (data.saleclubinfo[k]["아이언"] &&
                parseInt(data.saleclubinfo[k]["아이언"].salesum)) ||
                0,
              (data.saleclubinfo[k]["웨지"] &&
                parseInt(data.saleclubinfo[k]["웨지"].salesum)) ||
                0,
              (data.saleclubinfo[k]["퍼터"] &&
                parseInt(data.saleclubinfo[k]["퍼터"].salesum)) ||
                0,
            ]);

            this.saleData.saleclubinfo.push([
              datekey,
              data.saleclubinfo[k] || "",
            ]);
          });
        console.log(this.saleData.saleclubinfo);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });
      this.get_saleclublist();
    },
  },
  created() {
    this.get_saleclublist();
  },
  watch: {
    searchData: {
      handler() {
        this.get_saleclublist();
      },
      deep: true,
    },
  },
};
</script>