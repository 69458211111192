var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-main"},[_c('swiper',{staticClass:"swiper",attrs:{"options":{
      slidesPerView: 'auto',
      freeMode: true,
      initialSlide: _vm.tab - 1,
    }}},[_c('swiper-slide',{class:{ active: _vm.tab == 0 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '0',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'GoodsList',
              params: { goodstype: '0' },
            })
            .catch(function () {})}}},[_vm._v(" 전체 ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 1 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '1',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'GoodsList',
              params: { goodstype: '1' },
            })
            .catch(function () {})}}},[_vm._v(" 드라이버 ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 2 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '2',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'GoodsList',
              params: { goodstype: '2' },
            })
            .catch(function () {})}}},[_vm._v(" 우드 ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 3 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '3',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'GoodsList',
              params: { goodstype: '3' },
            })
            .catch(function () {})}}},[_vm._v(" 유티리티 ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 4 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '4',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'GoodsList',
              params: { goodstype: '4' },
            })
            .catch(function () {})}}},[_vm._v(" 아이언 ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 5 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '5',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'GoodsList',
              params: { goodstype: '5' },
            })
            .catch(function () {})}}},[_vm._v(" 웨지 ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 6 }},[_c('div',{class:{
          active: _vm.$route.params.goodstype === '6',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'GoodsList',
              params: { goodstype: '6' },
            })
            .catch(function () {})}}},[_vm._v(" 퍼터 ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 7 }},[_c('div',{class:{
          active: _vm.$route.name === 'ShaftList',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'ShaftList',
            })
            .catch(function () {})}}},[_vm._v(" 샤프트 ")])]),_c('swiper-slide',{class:{ active: _vm.tab == 8 }},[_c('div',{class:{
          active: _vm.$route.name === 'GripList',
        },on:{"click":function($event){_vm.$router
            .push({
              name: 'GripList',
            })
            .catch(function () {})}}},[_vm._v(" 그립 ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }