<template>
  <div class="container-fluid">
    <location />
    <div class="page shop">
      <b-form @submit="onSubmit">
        <div class="filter-group row">
          <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
            <div class="d-flex">
              <select
                class="w-auto"
                aria-label="검색조건선택 "
                name="searchtype"
                v-model="searchData.searchtype"
              >
                <option value="1">매장명</option>
                <option value="2">휴대전화번호</option>
              </select>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-truncate"
                  placeholder="검색어를 입력하세요."
                  aria-label="검색어를 입력하세요."
                  aria-describedby="button-addon"
                  name="searchname"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </div>

          <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
            <div class="d-flex">
              <select aria-label="정렬" v-model="searchData.findex">
                <option value="S.reg_date" selected>신청일순</option>
                <!-- <option value="P.nickname">제품명순</option> -->
                <option value="O.nickname">매장명순</option>
              </select>
              <select aria-label="목록개수" v-model="searchData.pagesize">
                <option value="10" selected>10개 보기</option>
                <option value="20">20개 보기</option>
                <option value="30">30개 보기</option>
              </select>
            </div>
          </div>
        </div>

        <div class="mb-3 pt-1">
          전체 매장<b class="text-primary"> {{ shoplistcnt }}</b
          >개
        </div>
      </b-form>

      <table class="table board">
        <thead>
          <tr>
            <th class="col-auto d-lg-table-cell w-1">
              <input
                type="checkbox"
                class="m-0"
                v-model="allSelected"
                @click="selectAll()"
              />
            </th>
            <th class="col-auto d-lg-table-cell">매장명</th>
            <!--             <th class="col-auto d-lg-table-cell text-center" v-if="admin_login">
              브랜드명
            </th> -->
            <th class="col-auto d-none d-lg-table-cell" colspan="4">
              기본정보
            </th>
            <th class="col-auto d-lg-table-cell w-1 text-center">룸번호</th>
            <th class="col-auto d-none d-lg-table-cell">회원수</th>
            <th class="col-auto d-none d-lg-table-cell">피팅횟수</th>
            <th class="col-auto d-none d-lg-table-cell">누적매출</th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in shoplist">
            <tr :key="index">
              <td class="col-auto d-lg-table-cell w-1">
                <input
                  type="checkbox"
                  class="m-0"
                  :value="index"
                  v-model="checkedShopidx"
                  @change="updateCheckall()"
                />
              </td>
              <td class="col-auto d-lg-table-cell text-start">
                <!-- <router-link
                    :to="{
                      name: 'ShopView',
                      params: { id: item.shopno },
                      query: {
                        searchtype: searchData.searchtype,
                        searchname: searchData.searchname,
                      },
                    }"
                    class="more"
                  >{{ item.shoptitle }}
                  </router-link> -->
                <ShopInfo
                  :shoptitle="item.shoptitle"
                  :shopno="item.shopno"
                  :brandno="item.brandno"
                  class="shop-name"
                />
                <div v-if="admin_login">
                  <b-button
                    size="xs"
                    variant="primary"
                    aria-label="클럽등록"
                    @click="BrandGoodsModalOpen(item.shopno, item.shoptitle)"
                    class="mt-2"
                    >클럽등록</b-button
                  >
                </div>
              </td>
              <!-- <td
                class="col-auto d-lg-table-cell text-center brand-name"
                v-if="admin_login"
              >
                {{ item.brandname }}
              </td> -->
              <td
                class="col-auto d-none d-lg-table-cell text-start"
                colspan="4"
              >
                <div class="row g-0 basic-info">
                  <div class="col-12">
                    <span class="material-icons-outlined">place</span
                    >{{ item.shopaddress }}
                  </div>
                  <div class="col-12 mt-2">
                    <div class="row row-cols-auto g-3">
                      <div class="col">
                        <span class="material-icons-outlined">phone</span
                        >{{ item.shopphone }}
                      </div>
                      <div class="col">
                        <span class="material-icons-outlined">print</span
                        >{{ item.shopfax }}
                      </div>
                      <div class="col">
                        <span class="material-icons-outlined">email</span
                        >{{ item.shopemail }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="col-auto d-lg-table-cell text-center">
                <b-dropdown
                  id="dropdown-1"
                  text="선택"
                  class=""
                  v-if="shoplist[index].shoproomlist"
                >
                  <template
                    v-for="(item1, index1) in shoplist[index].shoproomlist"
                  >
                    <b-dropdown-item :key="'a' + index1">
                      <RoomInfo
                        :shopno="item.shopno"
                        :shoptitle="item.shoptitle"
                        :roomno="item1.roomno"
                        :brandno="item.brandno"
                        :brandname="item.brandname"
                        @getshoplist="getShoplist"
                      />
                    </b-dropdown-item>
                  </template>
                </b-dropdown>

                <!--                 <div class="row">
                  <div
                    class="col"
                    style="border: 1px solid #ffffff; background-color: #dddddd"
                    v-for="(item1, index1) in shoplist[index].shoproomlist"
                    :key="index1"
                  >
                    <RoomInfo
                      :shopno="item.shopno"
                      :shoptitle="item.shoptitle"
                      :roomno="item1.roomno"
                      :brandno="item.brandno"
                      :brandname="item.brandname"
                      :getmethod="getShoplist"
                    />
                  </div>
                </div> -->
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <h5 class="fw-semibold text-cyan">
                  {{ item.shopmembercnt }}<small class="text-cyan">명</small>
                </h5>
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <h5 class="fw-semibold text-purple">
                  {{ item.shopfittingcnt }}<small class="text-purple">건</small>
                </h5>
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <h5 class="fw-semibold text-price">
                  {{ item.shopsalesum | comma("-")
                  }}<small class="text-price" v-if="item.shopsalesum"
                    >천원</small
                  >
                </h5>
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="8">
                <div class="toggle-content">
                  <!-- 
                  <div class="row" v-if="admin_login">
                    <div class="col-4 brand-name">브랜드명</div>
                    <div class="col-8">{{ item.brandname }}</div>
                  </div>
                   -->
                  <div class="row">
                    <div class="col-4">주소</div>
                    <div class="col-8">{{ item.shopaddress }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">대표전화</div>
                    <div class="col-8">{{ item.shophpone }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">팩스</div>
                    <div class="col-8">{{ item.shopfax }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">이메일</div>
                    <div class="col-8">{{ item.shopemail }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">회원수</div>
                    <div class="col-8">
                      <h5 class="fw-semibold text-cyan">
                        {{ item.shopmembercnt
                        }}<small class="text-cyan">명</small>
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">피팅횟수</div>
                    <div class="col-8">
                      <h5 class="fw-semibold text-purple">
                        {{ item.shopfittingcnt
                        }}<small class="text-purple">건</small>
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">누적매출</div>
                    <div class="col-8">
                      <h5 class="fw-semibold text-price">
                        {{ item.shopsalesum
                        }}<small class="text-price">천원</small>
                      </h5>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="11">데이터가 없습니다.</td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>

      <div class="btn-bottom-wrapper" v-if="admin_login">
        <b-button
          size="xl"
          variant="outline-secondary"
          aria-label="삭제"
          @click="shopDel"
          >삭제</b-button
        >
        <b-button
          size="xl"
          variant="secondary"
          aria-label="매장메일발송"
          @click="shopEmailSend(modal - email - shop)"
          >메일발송</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          aria-label="매장등록"
          @click="BrandShopModalOpen()"
          >매장등록</b-button
        >
      </div>

      <div class="btn-bottom-wrapper" v-else>
        <b-button
          size="xl"
          variant="outline-primary"
          aria-label="삭제"
          @click="shopDel"
          >삭제</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          aria-label="매장메일발송"
          @click="shopEmailSend(modal - email - shop)"
          >메일발송</b-button
        >
      </div>

      <b-modal
        id="modal-email-shop"
        size="lg"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">메일발송</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <!-- <section> -->
        <template>
          <div class="row g-0 table-type header-col border-1">
            <div class="col-sm-3">
              <span class="required">보내는 사람</span>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                placeholder="보내는 사람을 입력하세요"
                readonly
                :value="checkedManagerEmail"
              />
            </div>

            <div class="col-sm-3">
              <span class="required">받는 사람</span>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                placeholder="받는 사람을 입력하세요"
                :value="checkedShopEmail"
              />
            </div>

            <div class="col-sm-3">
              <span class="required">제목</span>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                placeholder="제목을 입력하세요"
              />
            </div>

            <div class="col-sm-3">
              <span class="required">내용</span>
            </div>
            <div class="col-sm-9">
              <b-form-textarea
                placeholder="내용을 입력하세요."
                class="mb-2"
                rows="10"
              ></b-form-textarea>
            </div>
          </div>
        </template>
        <!-- </section> -->

        <template #modal-footer="{}">
          <b-button
            size="md"
            variant="outline-secondary"
            aria-label="취소"
            @click="$bvModal.hide('modal-email-shop')"
          >
            취소
          </b-button>
          <b-button
            size="md"
            variant="secondary"
            aria-label="발송"
            @click="sendEmail"
          >
            발송
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-register-shop"
        ref="modal-register-shop"
        size="md"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">매장등록</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <!-- <section> -->
        <template>
          <b-form>
            <div class="row g-0 table-type header-col border-1">
              <div class="col-sm-3">
                <span>브랜드명 </span>
              </div>
              <div class="col-sm-9">
                <select
                  aria-label="브랜드선택 "
                  v-model="shopRegisterData.searchshopbrandno"
                >
                  <option value="0">브랜드선택</option>
                  <option
                    :value="br.brandno"
                    v-for="(br, index) in shopRegisterData.searchshopbrandlist"
                    :key="index"
                  >
                    {{ br.brandname }}
                  </option>
                </select>
              </div>

              <div class="col-sm-3">
                <span>매장 검색</span>
              </div>
              <div class="col-sm-9">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="매장명을 입력하세요."
                    v-model="shopRegisterData.searchshopname"
                  />
                  <button
                    class="btn btn-primary btn-search"
                    type="button"
                    id="button-addon"
                    @click="getSearchshoplist()"
                  ></button>
                </div>
              </div>

              <div class="col-sm-3" v-if="data_yn_register_shop">
                <span>매장 선택</span>
              </div>
              <div class="col-sm-9" v-if="data_yn_register_shop">
                <select
                  aria-label="매장선택 "
                  v-model="shopRegisterData.searchshopno"
                >
                  <option value="0">매장선택</option>
                  <option
                    :value="br.shopno"
                    v-for="(br, index) in shopRegisterData.searchshoplist"
                    :key="index"
                  >
                    {{ br.shopname }}
                  </option>
                </select>
              </div>
            </div>
          </b-form>
        </template>
        <!-- </section> -->

        <template #modal-footer="{}">
          <b-button
            size="md"
            variant="outline-secondary"
            aria-label="취소"
            @click="$bvModal.hide('modal-register-shop')"
          >
            취소
          </b-button>
          <b-button
            size="md"
            variant="secondary"
            aria-label="등록"
            @click="setShopRegister()"
          >
            등록
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-register-room"
        ref="modal-register-room"
        size="lg"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">클럽등록</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>
        <template>
          <div
            class="filter-group d-flex flex-column flex-sm-row align-items-center"
          >
            <h6
              class="col-12 col-sm-6 sub-title mt-2 mb-3 my-sm-0 text-capitalize"
            >
              {{ shopGoodsRegisterData.goodsshopname }}
            </h6>
            <div class="col-12 col-sm-3 d-flex me-sm-n1px mb-2 mb-sm-0">
              <select
                aria-label="브랜드선택"
                v-model="shopGoodsRegisterData.form.goodsbrandno"
              >
                <option value="0">브랜드선택</option>
                <option
                  :value="br.brandno"
                  v-for="(br, index) in shopGoodsRegisterData.roombrandlist"
                  :key="index"
                >
                  {{ br.brandname }}
                </option>
              </select>
            </div>
            <div class="input-group flex-nowrap">
              <select
                aria-label="룸선택"
                aria-describedby="button-addon"
                id="button-addon"
                v-model="shopGoodsRegisterData.form.goodsroomno"
              >
                <option value="0">룸선택</option>
                <option
                  :value="rm.roomno"
                  v-for="(rm, index) in shopGoodsRegisterData.roomlist"
                  :key="index"
                >
                  {{ rm.roomname }}
                </option>
              </select>
              <button
                class="btn btn-primary btn-search"
                type="button"
                id="button-addon"
                aria-label="검색"
                @click="getBrandGoodsInfoSearch()"
              ></button>
            </div>
          </div>

          <table class="table board">
            <thead>
              <tr>
                <th class="col-auto d-lg-table-cell w-1">
                  <input
                    type="checkbox"
                    class="m-0"
                    v-model="shopGoodsRegisterData.allGoodsSelected"
                    @click="selectGoodsAll()"
                  />
                </th>
                <th class="col-auto d-lg-table-cell">클럽구분</th>
                <th class="col-auto d-none d-lg-table-cell">이미지</th>
                <th class="col-auto d-lg-table-cell text-center">제품명</th>
                <th class="col-auto d-none d-lg-table-cell">강도</th>
                <th class="th-arrow">&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="shopGoodsRegisterData.goods_total_cnt">
              <template
                v-for="(item1, index1) in shopGoodsRegisterData.brandgoodslist"
              >
                <tr :key="index1">
                  <td class="col-auto d-lg-table-cell">
                    <input
                      type="checkbox"
                      class="form-check-input custom-checkbox m-0"
                      :value="index1"
                      v-model="
                        shopGoodsRegisterData.form.checkedGoodsidx[index1]
                      "
                      @change="updateGoodsCheckall()"
                    />
                  </td>
                  <td class="col-auto d-lg-table-cell goods-kind">
                    {{ club_type(item1.brandgoodstype) }}
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <img
                      :src="item1.brandgoodsimagepath | get_img"
                      class="img-thumbnail"
                    />
                  </td>
                  <td class="col-auto d-lg-table-cell goods-name">
                    {{ item1.brandgoodstitle }}({{ item1.brandshaftmaterial }})
                  </td>
                  <td class="col-auto d-none d-lg-table-cell">
                    <select
                      placeholder="강도"
                      v-model="
                        shopGoodsRegisterData.form.brandshaftmaterialcode[
                          index1
                        ]
                      "
                    >
                      <option value="">강도선택</option>
                      <option value="1">R</option>
                      <option value="2">SR</option>
                      <option value="3">S</option>
                      <option value="4">L</option>
                    </select>
                  </td>
                  <td class="td-arrow" @click="toggle_room_tr(index1)">
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': view_hide_room === index1 }"
                    />
                  </td>
                </tr>
                <tr
                  class="col-auto d-lg-none"
                  :class="{ show: view_hide_room === index1 }"
                  :key="index1"
                  v-if="view_hide_room === index1"
                >
                  <td>&nbsp;</td>
                  <td colspan="4">
                    <template
                      v-for="(
                        item1, index1
                      ) in shopGoodsRegisterData.brandgoodslist"
                    >
                      <div
                        class="toggle-content"
                        v-if="index1 == 0"
                        :key="index1"
                      >
                        <div class="row">
                          <div class="col-3 align-self-center">이미지</div>
                          <div class="col-9">
                            <img :src="item1.brandgoodsimagepath | get_img" class="img-thumbnail">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3 align-self-center">강도</div>
                          <div class="col-9">
                            <select
                              placeholder="강도"
                              v-model="
                                shopGoodsRegisterData.form
                                  .brandshaftmaterialcode[index1]
                              "
                              class="w-auto"
                            >
                              <option value="">강도선택</option>
                              <option value="1">R</option>
                              <option value="2">SR</option>
                              <option value="3">S</option>
                              <option value="4">L</option>
                            </select>
                          </div>
                        </div>
                        <!-- <div class="row">
                        <div class="col-4">등록일</div>
                        <div class="col-8">{{ item2.roomgoodsdate }}</div>
                      </div> -->
                      </div>
                    </template>
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">데이터가 없습니다</td>
              </tr>
            </tbody>
          </table>
        </template>
        <template #modal-footer="{}">
          <b-button
            size="md"
            variant="outline-secondary"
            aria-label="취소"
            @click="$bvModal.hide('modal-register-room')"
          >
            취소
          </b-button>
          <b-button
            size="md"
            variant="secondary"
            aria-label="등록"
            @click="setShopRoomRegister()"
          >
            등록
          </b-button>
        </template>
      </b-modal>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/shop";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "Stores",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchtype: this.$route.query.searchtype || 1,
        searchname: this.$route.query.searchname || "",
        findex: this.$route.query.findex || "S.reg_date",
        pagesize: parseInt(this.$route.query.pagesize) || 10,
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      shoplist: [],
      shoproomlist: [],
      rows: 10,
      shoplistcnt: 0,
      checkedShopidx: [],
      allSelected: false,
      checkedShopEmail: [],
      checkedManagerEmail: "",
      sendData: {
        title: "",
        content: "",
        shopno: [],
        email: [],
      },
      view_hide: null,
      view_hide_room: null,
      data_yn_register_shop: false,

      shopRegisterData: {
        searchshopbrandlist: [],
        searchshoplist: [],
        searchshopbrandno: 0,
        searchshopname: "",
        searchshopno: 0,
      },

      shopGoodsRegisterData: {
        goods_total_cnt: 0,
        brandgoodslist: [],
        allGoodsSelected: false,
        goodsshopname: "",
        roombrandlist: [],
        roomlist: [],
        checkedGoodsidxlength: 0,
        form: {
          goodsbrandno: 0,
          goodsshopno: 0,
          goodsroomno: 0,
          brandgoodsno: [],
          checkedGoodsidx: [],
          brandshaftmaterialcode: [],
          brandgoodscnt: 0,
        },
      },
      sendData: { fit_shop_pk: [] },
      roomno_: [],
      isChildmodal: true,
      admin_login: sessionStorage.getItem("isAdmin") == "1" ? true : false,
    };
  },
  methods: {
    getRoomInfo(shopno, idx) {
      if (this.roomno_[idx] != "undefined")
        this.$refs.apiRequest[idx].getRoomInfo(shopno, this.roomno_[idx]);
    },
    getShoplist() {
      ax.get_shoplist(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          if (data.pagination.shoplistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.shoplist = data.shoplist;

          for (let i = 0; i < data.shoplist.length; i++) {
            if (data.shoplist[i].shoproomlist) {
              for (let j = 0; j < data.shoplist[i].shoproomlist.length; j++) {
                this.shoplist[i].shoproomlist[j] =
                  data.shoplist[i].shoproomlist[j];
                // alert(data.shoplist[0].shoproomlist[i].roomno);
              }
            }
          }

          this.shoplistcnt = data.pagination.shoplistcnt;
          this.total_cnt = data.pagination.shoplistcnt;
          this.page_total = Math.ceil(data.pagination.shoplistcnt / this.rows);
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    getBrandlist() {
      ax.get_brandlist((flag, data) => {
        console.log(flag, data);
        if (flag) {
          this.shopRegisterData.searchshopbrandlist = data.brandlist;
        } else {
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },

    getRoomBrandlist(shopno) {
      ax.get_roombrandlist(shopno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          this.shopGoodsRegisterData.roombrandlist = data.brandlist;
        } else {
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    getShopRoomlist(shopno) {
      ax.get_shoproomlist(shopno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          // this.shopGoodsRegisterData.roomlistcnt = data.roomlist.length;
          this.shopGoodsRegisterData.roomlist = data.roomlist;
        } else {
          alert(data);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchtype: this.searchData.searchtype,
            searchname: this.searchData.searchname,
            pagesize: this.searchData.pagesize,
            findex: this.searchData.findex,
            brandno: this.searchData.brandno,
          },
        })
        .catch(() => {});
    },
    selectAll() {
      this.checkedShopidx = [];
      this.checkedShopEmail = [];
      // alert(this.allSelected);
      if (!this.allSelected) {
        for (let i = 0; i < this.shoplist.length; i++) {
          //if (this.searchData.pagesize > i) {
          this.checkedShopidx.push(i);
          this.checkedShopEmail.push(
            this.shoplist[this.checkedShopidx[i]].shopemail
          );
          //}
        }
      }
    },
    updateCheckall() {
      // this.allSelected = false;
      // alert(this.checkedShopidx.length);
      if (this.shoplist.length == this.checkedShopidx.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    linkGen(pageNum) {
      return `?page=${pageNum}&searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    toggle_room_tr(index) {
      if (this.view_hide_room === index) this.view_hide_room = null;
      else this.view_hide_room = index;
    },
    shopEmailSend() {
      if (this.checkedShopidx.length < 1) {
        /*this.$notify({
          group: 'alert',
          type: 'info',
          title: '정보',
          text: '발송하려는 회원을 선택해주세요.'
        })*/
        alert("이메일을 보낼 회원을 한 명 이상 선택하세요.");
      } else {
        this.checkedShopEmail = [];
        for (let i = 0; i < this.checkedShopidx.length; i++) {
          this.checkedShopEmail.push(
            this.shoplist[this.checkedShopidx[i]].shopemail
          );
        }

        this.$bvModal.show("modal-email-shop");
      }
    },
    sendEmail() {
      this.sendData.senderemail = this.checkedManagerEmail;
      this.sendData.email = [];
      this.sendData.shopno = [];
      for (let i = 0; i < this.checkedShopidx.length; i++) {
        this.sendData.email.push(
          this.shoplist[this.checkedShopidx[i]].shopemail
        );
        this.sendData.shopno.push(this.shoplist[this.checkedShopidx[i]].shopno);
      }
      this.sendData.emailcnt = this.checkedShopidx.length;

      if (this.sendData.title.length < 2 || this.sendData.content.length < 2) {
        /*this.$notify({
          group: 'alert',
          type: 'info',
          title: '정보',
          text: '제목 과 내용을 입력해주세요.'
        })*/
        alert("제목과 내용을 입력해주세요.");
        return false;
      } else {
        ax.sendEmail(this.sendData, (flag, response) => {
          if (flag) {
            /*this.$notify({
             group: 'alert',
             type: 'info',
             title: '정보',
              text: '이메일이 발송 되었습니다.'
            })*/
            alert(response.message);
          } else {
            /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '발송에 실패하였습니다.'
            })*/
            alert(response.message);
          }
        });
      }
    },
    getSearchshoplist() {
      if (!this.shopRegisterData.searchshopbrandno) {
        alert("브랜드를 선택해 주세요.");
        return false;
      }
      // alert(this.shopRegisterData.searchshopname);
      ax.get_searchshoplist(
        this.shopRegisterData.searchshopname,
        (flag, data) => {
          console.log(flag, data);
          if (flag) {
            this.data_yn_register_shop = true;
            this.shopRegisterData.searchshoplist = data.searchshoplist;
          } else {
            this.data_yn_register_shop = false;
            alert(data.message);
          }
        }
      );
    },
    setShopRegister() {
      if (!this.shopRegisterData.searchshopbrandno) {
        alert("브랜드를 선택해 주세요.");
        return false;
      }

      if (!this.shopRegisterData.searchshopno) {
        alert("매장을 선택해 주세요.");
        return false;
      }

      ax.set_shopregister(
        this.shopRegisterData.searchshopbrandno,
        this.shopRegisterData.searchshopno,
        (flag, response) => {
          console.log(flag, response);
          if (flag) {
            alert(response.message);
            this.getShoplist();
            this.$bvModal.hide("modal-register-shop");
            // this.sendData.shopmemo = "";
          } else {
            alert(response.message);
            return false;
          }
        }
      );
    },
    selectGoodsAll() {
      this.shopGoodsRegisterData.form.checkedGoodsidx = [];
      // alert(this.shopGoodsRegisterData.allGoodsSelected);
      if (!this.shopGoodsRegisterData.allGoodsSelected) {
        for (
          let i = 0;
          i < this.shopGoodsRegisterData.brandgoodslist.length;
          i++
        ) {
          this.shopGoodsRegisterData.form.checkedGoodsidx.push(true);
          // this.shopGoodsRegisterData.form.checkedGoodsidx.push(
          //     this.shopGoodsRegisterData.form.brandgoodsno[i]
          // );
        }
      }
    },
    updateGoodsCheckall() {
      // this.allSelected = false;
      // alert(this.shopGoodsRegisterData.brandgoodslist.length);
      this.shopGoodsRegisterData.checkedGoodsidxlength = 0;
      for (
        let i = 0;
        i < this.shopGoodsRegisterData.brandgoodslist.length;
        i++
      ) {
        if (this.shopGoodsRegisterData.form.checkedGoodsidx[i] === true) {
          this.shopGoodsRegisterData.checkedGoodsidxlength =
            this.shopGoodsRegisterData.checkedGoodsidxlength + 1;
        }
      }
      // alert(this.shopGoodsRegisterData.checkedGoodsidxlength);

      if (
        this.shopGoodsRegisterData.brandgoodslist.length ==
        this.shopGoodsRegisterData.checkedGoodsidxlength
      ) {
        this.shopGoodsRegisterData.allGoodsSelected = true;
      } else {
        this.shopGoodsRegisterData.allGoodsSelected = false;
      }
    },
    BrandShopModalOpen() {
      this.$refs["modal-register-shop"].modalOuterStyle.zIndex = 9999;
      this.$refs["modal-register-shop"].show();
      this.shopRegisterData.brandgoodslist = [];
      this.shopRegisterData.searchshoplist = [];
      this.shopRegisterData.searchshopbrandno = 0;
      this.shopRegisterData.searchshopname = 0;
      this.shopRegisterData.searchshopname = "";
      this.shopRegisterData.searchshopno = 0;
    },
    BrandGoodsModalOpen(shopno, shopname) {
      this.$refs["modal-register-room"].modalOuterStyle.zIndex = 9999;
      this.$refs["modal-register-room"].show();
      this.shopGoodsRegisterData.goodsshopname = shopname;
      this.getRoomBrandlist(shopno);
      this.getShopRoomlist(shopno);
      this.shopGoodsRegisterData.brandgoodslist = [];
      this.shopGoodsRegisterData.form.checkedGoodsidx = [];
      this.shopGoodsRegisterData.form.goodsbrandno = 0;
      this.shopGoodsRegisterData.form.goodsshopno = shopno;
      this.shopGoodsRegisterData.form.goodsroomno = 0;
      this.shopGoodsRegisterData.form.brandgoodsno = [];
    },
    getBrandGoodsInfoSearch() {
      if (!this.shopGoodsRegisterData.form.goodsbrandno) {
        alert("브랜드를 선택해 주세요.");
        return false;
      }
      ax.get_brandgoodsinfo(
        this.shopGoodsRegisterData.form.goodsbrandno,
        (flag, data) => {
          console.log(flag, data);
          if (flag) {
            this.shopGoodsRegisterData.allGoodsSelected = false;
            this.shopGoodsRegisterData.form.checkedGoodsidx = [];

            // this.playerno = playerno;
            this.$refs["modal-register-room"].modalOuterStyle.zIndex = 9999;
            this.$refs["modal-register-room"].show();
            // this.shopGoodsRegisterData.goodsshopname = shopname;

            this.shopGoodsRegisterData.brandgoodslist = data.brandgoodslist;

            if (data.brandgoodslist) {
              this.shopGoodsRegisterData.goods_total_cnt =
                data.brandgoodslist.length;
              for (let i = 0; i < data.brandgoodslist.length; i++) {
                this.shopGoodsRegisterData.form.brandshaftmaterialcode[i] =
                  data.brandgoodslist[i].brandshaftmaterialcode;
                this.shopGoodsRegisterData.form.brandgoodsno[i] =
                  data.brandgoodslist[i].brandgoodsno;
                // this.shopGoodsRegisterData.form.checkedGoodsidx[i] = false;
                this.shopGoodsRegisterData.form.checkedGoodsidx.push(false);
              }
            }
            // this.getShopRoomlist(shopno);
          } else {
            this.shopGoodsRegisterData.brandgoodslist = [];
            // alert(data);
          }
        }
      );
      // this.getRoomBrandlist(shopno);
      // this.getShopRoomlist(shopno);
    },
    setShopRoomRegister() {
      // alert(this.form.checklist.length);
      this.shopGoodsRegisterData.form.brandgoodscnt =
        this.shopGoodsRegisterData.form.checkedGoodsidx.length;

      if (!this.shopGoodsRegisterData.form.goodsbrandno) {
        alert("브랜드를 선택해 주세요.");
        return false;
      }

      if (!this.shopGoodsRegisterData.form.goodsroomno) {
        alert("룸 번호을 선택해 주세요.");
        return false;
      }

      // alert(this.shopGoodsRegisterData.form.checkedGoodsidx.length);
      if (this.shopGoodsRegisterData.form.checkedGoodsidx.length > 0) {
        for (
          let i = 0;
          i < this.shopGoodsRegisterData.form.checkedGoodsidx.length;
          i++
        ) {
          // alert(this.shopGoodsRegisterData.form.checkedGoodsidx[i]);
          // alert(this.shopGoodsRegisterData.form.brandshaftmaterialcode[i]);
          if (this.shopGoodsRegisterData.form.checkedGoodsidx[i] === true) {
            if (
              this.shopGoodsRegisterData.form.brandshaftmaterialcode[i] === ""
            ) {
              alert("강도를 선택하세요.");
              return false;
            }
          }
        }
      } else {
        alert("제품을 선택하세요.");
        return false;
      }
      ax.set_shoproomregister(
        this.shopGoodsRegisterData.form,
        (flag, response) => {
          console.log(flag, response);
          if (flag) {
            alert(response.message);
            this.getShoplist();
            // this.sendData.shopmemo = "";
            this.$bvModal.hide("modal-register-room");
          } else {
            /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '등록에 실패하였습니다.'
            })*/
            alert(response.message);
          }
        }
      );
    },
    shopDel() {
      if (this.checkedShopidx.length < 1) {
        alert("항목을 선택하세요.");
      } else {
        if (confirm("체크한 항목이 삭제됩니다.\n" + "진행하시겠습니까?")) {
          this.sendData.fit_shop_pk = [];
          for (let i = 0; i < this.checkedShopidx.length; i++) {
            this.sendData.fit_shop_pk.push(
              this.shoplist[this.checkedShopidx[i]].fit_shop_pk
            );
          }

          ax.post_shopdel(this.sendData, (flag, response) => {
            alert(response.message);
            if (flag) {
              this.getShoplist();
              this.checkedShopidx = [];
            }
          });
        }
      }
    },
    // goods_imgview(imagename) {
    //   if (imagename !== null && imagename !== "") {
    //     //   return this.adlist[index].path + "/" + this.adlist[index].adimagepath;
    //     return process.env.VUE_APP_S3_BUCKET_URL + "/" + imagename;
    //   } else {
    //     return "/img/no-img.jpg";
    //   }
    // },
  },
  mixins: [myMixin],
  created() {
    this.getShoplist();
    this.getBrandlist();
    ax.get_brandview((flag, data) => {
      if (flag) {
        this.checkedManagerEmail = data.brandinfo.email;
      } else {
        alert(data);
      }
    });
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.getShoplist();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.getShoplist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.getShoplist();
    },
  },
};
</script>
