<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <b-form @submit="onSubmit">
        <button
          class="btn btn-xs ps-0 mt-lg-1 mb-3"
          type="button"
          @click="gripDelete()"
          v-if="gripno"
        >
          <span class="icon-delete-bin"></span>
          <span class="d-inline">&nbsp;삭제</span>
        </button>
        <div class="row g-0 table-type header-col">
          <div class="col-sm-3">
            <span>제품명</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="제품명"
              name="griptitle"
              id="griptitle"
              v-model="form.griptitle"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span>규격</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="규격"
              name="gripspec"
              id="gripspec"
              v-model="form.gripspec"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span>무게<small class="fw-normal">(g)</small></span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="무게"
              name="gripweight"
              id="gripweight"
              v-model="form.gripweight"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">대표 이미지</span>
          </div>
          <div class="col-sm-9">
            <b-form-file
              v-model="file1"
              ref="file1"
              :state="Boolean(file1)"
              placeholder="파일을 선택하거나 여기에 드롭하세요."
              drop-placeholder="여기에 드롭하세요."
              @change="fileSelect"
            >
            </b-form-file>
            <p
              class="mt-2 text-subscript text-truncate more"
              v-if="form.gripimagenametxt"
              @click="$bvModal.show('modal-img-thumbnail')"
            >
              {{ form.gripimagenametxt }}
            </p>
          </div>

          <div class="col-sm-3">
            <span>가격<small class="fw-normal text-price">(원)</small></span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="가격"
              name="gripprice"
              id="gripprice"
              v-model="form.gripprice"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            aria-label="취소"
            @click="$router.go(-1)"
            >취소</b-button
          >
          <b-button type="submit" size="xl" variant="primary" aria-label="등록">
            {{ gripno ? "수정" : "등록" }}</b-button
          >
        </div>
      </b-form>
      <b-modal
        id="modal-img-thumbnail"
        size="lg"
        centered
        scrollable
        body-class="d-flex justify-content-center"
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">이미지</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <img :src="form.gripimagenametxt | get_img" class="mw-100 h-100" />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            aria-label="닫기"
            @click="cancel()"
          >
            닫기
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/goods";
import "@/filter/common";

export default {
  name: "GripWrite",
  data() {
    return {
      form: {
        brandno: "0",
        griptitle: "",
        gripspec: "",
        gripweight: "",
        gripprice: "",
        gripimagename: "",
        gripimagenametxt: "",
      },
      file1: null,
      // targetFileName: "",
      // gripimagename: "",
      gripno: this.$route.params.gripno || 0,

      mainbrandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
    };
  },
  components: {
    location,
  },
  methods: {
    gripDelete() {
      ax.delete_grip(this.gripno, (flag, data) => {
        alert(data.message);
        if (flag) {
          this.$router.push({
            path: "/goods/griplist",
          });
        } else {
        }
      });
    },
    getGripview() {
      if (this.mainbrandno) {
        this.form.brandno = this.mainbrandno;
      }
      if (this.gripno) {
        ax.get_gripview(this.gripno, {}, (flag, data) => {
          console.log(flag, data);
          this.form.griptitle = data.gripinfo.griptitle;
          this.form.gripspec = data.gripinfo.gripspec;
          this.form.gripweight = data.gripinfo.gripweight;
          this.form.gripprice = data.gripinfo.gripprice;
          this.form.gripimagename = data.gripinfo.gripimagename;
          this.form.gripimagenametxt = data.gripinfo.gripimagename;

          if (sessionStorage.getItem("isAdmin") == "1") {
            if (
              data.gripinfo.brandno &&
              this.mainbrandno != data.gripinfo.brandno
            ) {
              this.$store.commit("setBrandno", data.gripinfo.brandno);
              window.location.reload(true);
            }
            this.form.brandno = data.gripinfo.brandno;
          }

          if (!flag) {
            alert(data);
          }
        });
      }
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.griptitle === "") {
        alert("제품명을 입력하세요.");
        return false;
      }
      if (this.form.gripspec === "") {
        alert("규격을 입력하세요.");
        return false;
      }
      if (this.form.gripweight === "") {
        alert("무게를 입력하세요.");
        return false;
      }
      if (this.form.gripprice === "") {
        alert("가격을 입력하세요.");
        return false;
      }

      ax.post_gripwrite(this.form, this.gripno, (flag, data) => {
        if (flag) {
          alert(data.message);
          // this.$router.push({
          //   path: "/ad/movieadlist/",
          // });
        } else {
          alert(data.message);
        }
      });
    },
    fileSelect(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.gripimagename = e.dataTransfer.files[0];
      } else {
        this.form.gripimagename = e.target.files[0];
      }
    },
    // handleFileChange(e) {
    //   this.targetFileName = e.target.files[0].name;
    //   this.form.gripimagename = this.$refs.file.files[0];
    // },
  },
  created() {
    this.getGripview();
  },
};
</script>
