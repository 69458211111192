<template>
  <div class="tab-main">
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: 0,
        slideToClickedSlide: true,
      }"
      class="swiper"
    >
      <swiper-slide
        :class="{
          active: $route.query.goodstype == '0' || !$route.query.goodstype,
        }"
      >
        <div
          @click="
            $router
              .push({
                name: 'FittingList',
                query: { goodstype: '0' },
              })
              .catch(() => {})
          "
        >
          전체
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: $route.query.goodstype == 1 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingList',
                query: { goodstype: 1 },
              })
              .catch(() => {})
          "
        >
          드라이버
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: $route.query.goodstype == 2 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingList',
                query: { goodstype: 2 },
              })
              .catch(() => {})
          "
        >
          우드
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: $route.query.goodstype == 3 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingList',
                query: { goodstype: 3 },
              })
              .catch(() => {})
          "
        >
          유틸
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: $route.query.goodstype == 4 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingList',
                query: { goodstype: 4 },
              })
              .catch(() => {})
          "
        >
          아이언
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: $route.query.goodstype == 5 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingList',
                query: { goodstype: 5 },
              })
              .catch(() => {})
          "
        >
          웨지
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: $route.query.goodstype == 6 }">
        <div
          @click="
            $router
              .push({
                name: 'FittingList',
                query: { goodstype: 6 },
              })
              .catch(() => {})
          "
        >
          퍼터
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Tab",
  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
