<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page stats">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >

          <div class="filter-group row">
            <div class="col-6 col-sm-4">
              <select
                aria-label="연월 선택"
                v-model="searchData.datetype"
              >
                <option value="year">연별</option>
                <option value="month">월별</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <date-picker
                v-model="searchData.searchdate"
                valueType="format"
                :disabled="searchData.datetype == 'year'"
                :type="searchData.datetype == 'month' ? 'year' : 'month'"
                placeholder="날짜선택"
                aria-label="날짜선택"
                class="w-100"
              ></date-picker>
            </div>
          </div>

          <div class="card rounded-0 mb-4">
            <div
              class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
            >
              총회원
              <span class="align-self-center ms-auto price-unit text-secondary"
                >(단위 : 명)</span
              >
            </div>
            <div class="card-body">
              <GChart
                :type="chartsData.chartsType"
                :data="chartsData.saleData"
                :options="chartsData.saletotalOptions"
              />
            </div>
          </div>          

          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th sticky-column>날짜</b-th>
                <b-th
                  v-for="(val, key) in saleData.salelist"
                  :key="'a' + key"
                >
                  {{ val[0] }}
                </b-th>
                <b-th>합계</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td sticky-column>가입자</b-td>
                <b-td
                  v-for="(val, key) in saleData.salelist"
                  :key="'b' + key"
                >
                  {{ val[1] || "-" }}
                </b-td>
                <b-td>
                  {{ saleData.total_salesum | comma("-") }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/stats/statsmembertotalTab";
import ax from "@/api/stats";
import "@/filter/common";
import { GChart } from "vue-google-charts/legacy";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";

export default {
  name: "StatsMain",
  components: {
    location,
    tab,
    GChart,
    DatePicker,
  },
  data() {
    return {
      searchData: {
        datetype:
          this.$route.query.datetype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].datetype) ||
          "month",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        forder: "",
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      chartsData: {
        chartsType: "ColumnChart",
        saleData: [],
        saletotalOptions: {
          legend: { position: "bottom" },
        },
      },
      saleData: { saleinfo: [], salelist: {}, total_salesum: {} },
      view_hide: null,
    };
  },
  methods: {
    get_memberlist() {
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });

      ax.get_memberlist(this.searchData, (flag, data = "") => {
        this.saleData.total_salesum = data.total_salesum;

        this.saleData.salelist = [];
        this.chartsData.saleData = [["기간", "가입수"]];
        Object.keys(data.salelist)
          .sort((a, b) => {
            return new Date(a) - new Date(b);
          })
          .forEach((k) => {
            let datekey = k;
            if (this.searchData.datetype === "year")
              datekey = parseInt(k.substring(k.length - 4, k.length)) + "년";
            if (this.searchData.datetype === "month")
              datekey = parseInt(k.substring(k.length - 2, k.length)) + "월";

            this.chartsData.saleData.push([
              datekey,
              parseInt(data.salelist[k].membercnt) || 0,
            ]);
            this.saleData.salelist.push([
              datekey,
              parseInt(data.salelist[k].membercnt) || 0,
            ]);
          });

        console.log("saleshopData", this.chartsData.saleData);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      this.get_memberlist();
    },
  },
  created() {
    this.get_memberlist();
  },
  watch: {
    searchData: {
      handler() {
        this.get_memberlist();
      },
      deep: true,
    },
  },
};
</script>