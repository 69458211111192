<template>
  <div class="container-fluid">
    <locations />

    <div class="board_view page ps-5 pe-5">
      <div>
        <div class="col-12 mt-5 mb-4">
          <!-- 최상단타이틀 -->

          <h2>
            [{{ answerinfo.category | get_custormer_category }}]
            {{ answerinfo.noticetitle }}
          </h2>
        </div>

        <article class="article-header row pt-3 pb-3 bg-light">
          <!-- 닉네임날짜조회수 -->
          <div class="d-flex flex-wrap">
            <div class="col-12 col-sm-6 color-black ps-2">
              <!-- <span
                class="icon badge"
                :class="[
                  { 'fill-purple': answerinfo.category === '공지사항' },
                  { 'fill-blue': answerinfo.category === 'A/S' },
                  { 'fill-purple': answerinfo.category === '개선사항' },
                  { 'fill-blue': answerinfo.category === '기타' },
                ]"
              >
                {{ answerinfo.category }}
              </span> -->

              <span class="text-black">닉네임 :</span> {{ answerinfo.writer }}
            </div>
            <div class="d-flex col-12 col-sm-6 pe-2">
              <div class="ms-auto">
                <span class="me-2">
                  <i class="material-icons">date_range </i>
                  {{ answerinfo.regdate }}
                </span>
                <span>
                  <i class="material-icons">visibility</i>
                  {{ answerinfo.viewcnt | comma }}
                </span>
              </div>
            </div>
          </div>
        </article>
        <article class="article-attach" v-if="answerinfo.as_filename">
          <!-- 미확인 -->
          <a :href="answerinfo.as_filepath">
            <i class="material-icons-outlined">file_download</i>
            <span>{{ answerinfo.as_filename }}</span>
          </a>
        </article>
        <article class="article-body">
          <!-- 본문 -->
          <span v-html="answerinfo.content"></span>
        </article>
        <b-form @submit="onSubmit"
          ><!-- 하단답변부분 -->
          <div class="row g-0 table-type header-col">
            <div class="col-sm-3">
              <span class="required">예상 청구 비용</span>
            </div>
            <div class="col-sm-9">
              <b-form-input
                placeholder="0"
                class="text"
                name="answerprice"
                v-model="form.answerprice"
                required
              ></b-form-input>
            </div>

            <div class="col-sm-3">
              <span class="required">답변</span>
            </div>
            <div class="col-sm-9">
              <ckeditor
                tag-name="textarea"
                ref="cktext"
                v-model="form.answercontent"
                :config="editorConfig"
              />
            </div>

            <div class="col-sm-3">
              <span>답변 파일 첨부</span>
            </div>
            <div class="col-sm-9">
              <b-form-file
                v-model="file1"
                ref="file1"
                :state="Boolean(file1)"
                placeholder="파일을 선택하거나 여기에 드롭하세요."
                drop-placeholder="여기에 드롭하세요."
                @change="fileSelect"
              >
              </b-form-file>

              <article class="article-attach" v-if="form.as_ans_filenametxt">
                <span>{{ form.as_ans_filenametxt }}</span>
                <a :href="answerinfo.as_ans_download_link">
                  <i class="material-icons-outlined">file_download</i>
                </a>
                <i class="material-icons-outlined" @click="fileDelete"
                  >delete_outline</i
                >
              </article>
            </div>
          </div>
          <div class="btn-bottom-wrapper">
            <!-- 버튼그룹 -->
            <b-button
              size="xl"
              variant="outline-primary"
              aria-label="취소"
              @click="$router.go(-1)"
              >취소</b-button
            >
            <b-button
              size="xl"
              variant="primary"
              type="submit"
              aria-label="답변"
              >답변</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/customer";
import "@/filter/common";

export default {
  name: "NoticeWrite",
  data() {
    return {
      form: {
        answercontent: "",
        answerprice: "",
        as_filename: "",
        as_filenametxt: "",
        as_ans_filename: "",
        as_ans_filenametxt: "",
      },
      editorConfig: {
        // The configuration of the editor.
        height: "200px",
        language: "en",
        toolbar: [
          ["Source"],
          ["Styles", "Format", "Font", "FontSize"],
          ["Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
        ],
      },
      answerinfo: {},
      file1: null,

      noticeno: this.$route.params.noticeno || 0,
      mainbrandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
    };
  },
  components: {
    locations,
  },
  methods: {
    get_custormeranswerview() {
      if (this.mainbrandno) {
        this.form.brandno = this.mainbrandno;
      }
      if (this.noticeno) {
        ax.get_custormeranswerview(this.noticeno, {}, (flag, data, code) => {
          this.answerinfo = data.answerinfo;
          this.form.answercontent = data.answerinfo.answercontent;
          this.form.answerprice = data.answerinfo.answerprice;
          this.form.as_ans_filename = data.answerinfo.as_ans_filename;
          this.form.as_ans_filenametxt = data.answerinfo.as_ans_filename;
          if (!flag) {
            alert(data);
          }
        });
      }
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.answercontent === "") {
        alert("답변을 입력하세요.");
        return false;
      }

      if (this.form.answerprice === "") {
        alert("예상 청구 비용을 입력하세요.");
        return false;
      }

      ax.post_custormeranswerwrite(this.form, this.noticeno, (flag, data) => {
        if (flag) {
          alert(data.message);
          this.$router.push({
            path: "/customer/noticeview/" + data.noticeno,
          });
        } else {
          alert(data.message);
        }
      });
    },
    fileDelete() {
      if (confirm("정말로 삭제 하시겠습니까?")) {
        ax.delete_custormerfile(this.noticeno, "as_ans", (flag, data) => {
          alert(data.message);
          if (flag) this.get_custormeranswerview();
          else return;
        });
      }
    },
    fileSelect(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.as_ans_filename = e.dataTransfer.files[0];
      } else {
        this.form.as_ans_filename = e.target.files[0];
      }
    },
  },
  // watch: {
  //   "form.newpassword": {
  //     handler: function (val) {
  //       const idReg2 = /(?=.*\d)(?=.*[a-zA-ZS]).{8,30}/; // 문자, 숫자 1개이상 포함, 8자리 이상
  //       if (!idReg2.test(val)) {
  //         this.formValidation.password =
  //           "Please enter a password of at least 8 characters and a maximum of 30 characters with including letters and numbers.";
  //       } else {
  //         this.formValidation.password = "this password is available.";
  //       }
  //     },
  //   },
  // },
  created() {
    this.get_custormeranswerview();
  },
};
</script>
