<template>
  <div class="container-fluid">
    <locations />

    <div class="page">
      <b-form @submit="onSubmit">
        <div class="row g-0 table-type header-col">
          <div class="col-sm-3">
            <span class="required">제목</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder=""
              name="noticetitle"
              v-model="form.noticetitle"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">분류</span>
          </div>
          <div class="col-sm-9">
            <select v-model="form.noticecategory" disabled>
              <option value="1">공지사항</option>
              <option value="2">A/S</option>
              <option value="3">제안사항</option>
            </select>
          </div>

          <div class="col-sm-3">
            <span>파일 첨부</span>
          </div>
          <div class="col-sm-9">
            <b-form-file
              v-model="file1"
              ref="file1"
              :state="Boolean(file1)"
              placeholder="파일을 선택하거나 여기에 드롭하세요."
              drop-placeholder="여기에 드롭하세요."
              @change="fileSelect"
            >
            </b-form-file>

            <article class="article-attach" v-if="form.as_filenametxt">
              기존 파일 :

              <span>{{ form.as_filenametxt }}</span>
              <a :href="form.as_download_link">
                <i class="material-icons-outlined">file_download</i>
              </a>
              <i class="material-icons-outlined" @click="fileDelete"
                >delete_outline</i
              >
            </article>
          </div>

          <div class="col-sm-3">
            <span class="required">내용</span>
          </div>
          <div class="col-sm-9">
            <ckeditor
              tag-name="textarea"
              ref="cktext"
              v-model="form.content"
              :config="editorConfig"
            />
          </div>
        </div>

        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            aria-label="취소"
            @click="$router.go(-1)"
            >취소</b-button
          >
          <b-button
            size="xl"
            variant="primary"
            type="submit"
            aria-label="등록"
            >{{ noticeno ? "수정" : "등록" }}</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/customer";
import "@/filter/common";

export default {
  name: "NoticeWrite",
  data() {
    return {
      form: {
        brandno: "0",
        noticetitle: "",
        noticecategory: "1",
        content: "",
        as_filename: "",
        as_filenametxt: "",
      },
      file1: null,
      noticeno: this.$route.params.noticeno || 0,
      editorConfig: {
        // The configuration of the editor.
        height: "200px",
        language: "en",
        toolbar: [
          ["Source"],
          ["Styles", "Format", "Font", "FontSize"],
          ["Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
        ],
      },
      mainbrandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
    };
  },
  components: {
    locations,
  },
  methods: {
    get_custormerview() {
      if (this.mainbrandno) {
        this.form.brandno = this.mainbrandno;
      }
      if (this.noticeno) {
        ax.get_custormerview(this.noticeno, {}, "", (flag, data) => {
          console.log(flag, data);
          this.form.noticetitle = data.noticeinfo.noticetitle;
          this.form.noticecategory = data.noticeinfo.category;
          this.form.content = data.noticeinfo.content;
          this.form.as_filepath = data.noticeinfo.as_filepath;

          this.form.as_filename = data.noticeinfo.as_filename;
          this.form.as_filenametxt = data.noticeinfo.as_filename;

          if (sessionStorage.getItem("isAdmin") == "1") {
            if (
              data.noticeinfo.brandno &&
              this.mainbrandno != data.noticeinfo.brandno
            ) {
              this.$store.commit("setBrandno", data.noticeinfo.brandno);
              // window.location.reload(true);
            }
            this.form.brandno = data.noticeinfo.brandno;
          }

          if (!flag) {
            alert(data);
          }
        });
      }
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.noticetitle === "") {
        alert("제목을 입력하세요.");
        return false;
      }

      if (this.form.content === "") {
        alert("내용을 입력하세요.");
        return false;
      }

      ax.post_custormerwrite(this.form, this.noticeno, (flag, data) => {
        if (flag) {
          alert(data.message);
          this.$router.push({
            path: "/customer/noticeview/" + data.noticeno,
          });
        } else {
          alert(data.message);
        }
      });
    },
    fileDelete() {
      if (confirm("정말로 삭제 하시겠습니까?")) {
        ax.delete_custormerfile(this.noticeno, "as", (flag, data) => {
          alert(data.message);
          if (flag) this.get_custormerview();
          else return;
        });
      }
    },
    fileSelect(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.as_filename = e.dataTransfer.files[0];
      } else {
        this.form.as_filename = e.target.files[0];
      }
    },
  },
  // watch: {
  //   "form.newpassword": {
  //     handler: function (val) {
  //       const idReg2 = /(?=.*\d)(?=.*[a-zA-ZS]).{8,30}/; // 문자, 숫자 1개이상 포함, 8자리 이상
  //       if (!idReg2.test(val)) {
  //         this.formValidation.password =
  //           "Please enter a password of at least 8 characters and a maximum of 30 characters with including letters and numbers.";
  //       } else {
  //         this.formValidation.password = "this password is available.";
  //       }
  //     },
  //   },
  // },
  created() {
    this.get_custormerview();
  },
};
</script>
