<template>
  <div class="container-fluid">
    <locations />

    <div class="page">
      <b-form @submit="onSubmit">
        <div class="row g-0 table-type header-col">
          <div class="col-sm-3">
            <span>아이디</span>
          </div>
          <div class="col-sm-9">
            {{ form.brandid }}
          </div>

          <div class="col-sm-3">
            <span>새 비밀번호</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="새 비밀번호"
              name="newpassword"
              v-model="form.newpassword"
              required
            ></b-form-input>
            <small>{{ formValidation.password }}</small>
          </div>

          <div class="col-sm-3">
            <span>새 비밀번호 확인</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="새 비밀번호 확인"
              name="newpassword_re"
              v-model="form.newpassword_re"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">상호</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="상호 2글자 이상 20글자 미만"
              name="brandname"
              v-model="form.brandname"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">사업자등록번호</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="사업자등록번호"
              name="brandcomno"
              v-model="form.brandcomno"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">대표전화</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="대표전화"
              name="brandphone"
              v-model="form.brandphone"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span>팩스</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="팩스"
              name="brandfax"
              v-model="form.brandfax"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">주소</span>
          </div>
          <div class="col-sm-9">
            <b-row class="g-2">
              <b-col sm="12" class="input-group flex-nowrap">
                <b-form-input
                  type="text"
                  placeholder="우편번호"
                  name="zipnum"
                  v-model="form.zipnum"
                  required
                  readonly
                ></b-form-input>
                <b-button
                  size="md"
                  variant="primary"
                  aria-label="우편번호 검색"
                  @click="search()"
                  >우편번호 검색</b-button
                >
              </b-col>
              <b-col sm="12"
                ><b-form-input
                  type="text"
                  placeholder="도로명주소"
                  id="roadAddress"
                  name="address1"
                  v-model="form.address1"
                  required
                  readonly
                ></b-form-input
              ></b-col>
              <b-col sm="12"
                ><b-form-input
                  type="text"
                  placeholder="지번주소"
                  id="jibunAddress"
                  name="address2"
                  v-model="form.address2"
                  required
                  readonly
                ></b-form-input
              ></b-col>
              <b-col sm="12"
                ><b-form-input
                  type="text"
                  placeholder="상세주소"
                  id="detailAddress"
                  name="address3"
                  v-model="form.address3"
                  required
                ></b-form-input
              ></b-col>
              <b-col sm="12"
                ><b-form-input
                  type="text"
                  placeholder="참고항목"
                  id="extraAddress"
                  name="address4"
                  v-model="form.address4"
                  required
                ></b-form-input
              ></b-col>
            </b-row>
          </div>

          <div class="col-sm-3">
            <span class="required">담당부서</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="담당부서 30글자 미만"
              name="managerpart"
              v-model="form.managerpart"
              required
            ></b-form-input>
          </div>
          <div class="col-sm-3">
            <span class="required">담당자</span>
          </div>
          <div class="col-sm-9">
            <b-row class="g-2">
              <b-col sm="9">
                <b-form-input
                  type="text"
                  placeholder="담당자명 30글자 미만"
                  name="managername"
                  v-model="form.managername"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="3">
                <select
                  placeholder="담당자직책"
                  name="managergrade"
                  v-model="form.managergrade"
                  required
                >
                  <option value="1" :selected="form.managergrade === 1">
                    차장
                  </option>
                  <option value="2" :selected="form.managergrade === 2">
                    과장
                  </option>
                  <option value="3" :selected="form.managergrade === 3">
                    대리
                  </option>
                  <option value="3" :selected="form.managergrade === 3">
                    사원
                  </option>
                </select>
              </b-col>
            </b-row>
          </div>

          <div class="col-sm-3">
            <span class="required">담당자 직통번호</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="담당자 직통번호"
              name="managerphone"
              v-model="form.managerphone"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">담당자 휴대번호</span>
          </div>
          <div class="col-sm-9">
            <b-row class="g-2">
              <b-col sm="9">
                <b-form-input
                  type="text"
                  placeholder="담당자 휴대번호"
                  name="managermobile"
                  v-model="form.managermobile"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="3" class="d-flex align-items-center">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="managermobilechk"
                  id="flexCheck1"
                  v-model="managermobilechk"
                />
                <label for="flexCheck1">변경</label>
              </b-col>
            </b-row>
          </div>

          <div class="col-sm-3" v-if="managermobilechk === true">
            <span>담당자 새 휴대번호</span>
          </div>
          <div class="col-sm-9" v-if="managermobilechk === true">
            <b-form-input
              type="text"
              placeholder="담당자 새 휴대번호"
              name="newmanagermobile"
              v-model="form.newmanagermobile"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">이메일</span>
          </div>
          <div class="col-sm-9">
            <b-form-input
              type="text"
              placeholder="공백없이 100글자 미만"
              name="manageremail"
              v-model="form.manageremail"
              required
            ></b-form-input>
          </div>

          <div class="col-sm-3">
            <span class="required">계좌번호</span>
          </div>
          <div class="col-sm-9">
            <b-row class="g-2">
              <b-col sm="4">
                <b-form-input
                  type="text"
                  placeholder="은행명 30글자 미만"
                  name="depositbank"
                  v-model="form.depositbank"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  placeholder="계좌번호"
                  name="depositno"
                  v-model="form.depositno"
                  required
                ></b-form-input>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  type="text"
                  placeholder="계좌주"
                  name="depositname"
                  v-model="form.depositname"
                  required
                ></b-form-input>
              </b-col>
              <small>계좌번호 변경은 (주)엑스골프 에 문의 바랍니다.</small>
            </b-row>
          </div>
          <!-- <div class="row regis-btn">
            <div class="col-lg-6 alone">
              <button
                class="btn btn-outline btn-xl btn1"
                type="button"
                @click="$router.go(-1)"
              >
                취소
              </button>
            </div>
            <div class="col-lg-6 alone">
              <button class="btn btn-primary btn-xl btn1" type="submit">
                적용
              </button>
            </div>
          </div> -->
        </div>

        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            aria-label="취소"
            @click="$router.go(-1)"
            >취소</b-button
          >
          <b-button type="submit" size="xl" variant="primary" aria-label="등록">
            {{ form.brandno ? "수정" : "등록" }}</b-button
          >
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import locations from "@/components/Location";
import ax from "@/api/account";
import "@/filter/common";

export default {
  name: "BrandPwdCheck",
  data() {
    return {
      profile: [],
      form: {
        brandno: 0,
        brandid: "",
        newpassword: "",
        newpassword_re: "",
        brandname: "",
        brandcomno: "",
        brandphone: "",
        brandfax: "",
        zipnum: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        managerpart: "",
        managername: "",
        managergrade: 1,
        managerphone: "",
        managermobile: "",
        newmanagermobile: "",
        manageremail: "",
        depositbank: "",
        depositno: "",
        depositname: "",
      },

      formValidation: {
        password:
          "비밀번호는 영문, 숫자를 포함하여 최소 8자, 최대 30자 이내로 입력해주세요.",
      },
      managermobilechk: false,
      view_hide: null,
      VUE_APP_S3_BUCKET_URL: process.env.VUE_APP_S3_BUCKET_URL,
      targetFileName: null,
    };
  },
  props: ["password_yn"],
  components: {
    locations,
  },
  methods: {
    step_chk() {
      if (this.password_yn !== "y") {
        alert("비밀번호 확인이 필요합니다.");
        this.$router.push({ name: "BrandPwdCheck" });
      }
    },
    get_brandinfo() {
      ax.get_brandview((flag, data) => {
        if (flag) {
          this.form = data.brandinfo;
        } else {
          alert(data.message);
        }
      });
    },
    search() {
      //@click을 사용할 때 함수는 이렇게 작성해야 한다.
      let postcode = null;
      if (postcode === null) {
        postcode = new window.daum.Postcode({
          oncomplete: (data) => {
            //function이 아니라 => 로 바꿔야한다.
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

            // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            var roadAddr = data.roadAddress; // 도로명 주소 변수
            var extraRoadAddr = ""; // 참고 항목 변수

            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraRoadAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraRoadAddr +=
                extraRoadAddr !== ""
                  ? ", " + data.buildingName
                  : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraRoadAddr !== "") {
              extraRoadAddr = " (" + extraRoadAddr + ")";
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            this.form.zipnum = data.zonecode;
            this.form.address1 = roadAddr;
            this.form.address2 = data.jibunAddress;

            // 참고항목 문자열이 있을 경우 해당 필드에 넣는다.
            if (roadAddr !== "") {
              this.form.address4 = extraRoadAddr;
            } else {
              this.form.address4 = "";
            }
          },
        }).open();
      }
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.newpassword) {
        if (this.form.newpassword_re === "") {
          alert("새로운 패스워드 확인를 입력하세요.");
          return false;
        }

        if (this.form.newpassword !== this.form.newpassword_re) {
          alert("새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.");
          return false;
        }
      }

      if (this.form.brandname === "") {
        alert("상호명을 입력하세요.");
        return false;
      }

      if (this.form.brandcomno === "") {
        alert("사업자 등록번호를 입력하세요.");
        return false;
      }

      if (this.form.brandphone === "") {
        alert("대표번호를 입력하세요.");
        return false;
      }

      if (this.form.brandfax === "") {
        alert("팩스번호를 입력하세요.");
        return false;
      }

      if (this.form.brandphone === "") {
        alert("대표번호를 입력하세요.");
        return false;
      }
      if (this.form.zipnum === "") {
        alert("주소를 입력하세요.");
        return false;
      }

      if (this.form.address3 === "") {
        alert("상세주소를 입력하세요.");
        return false;
      }

      if (this.form.managerpart === "") {
        alert("담당부서를 입력하세요.");
        return false;
      }

      if (this.form.managername === "") {
        alert("담당자를 입력하세요.");
        return false;
      }

      if (this.form.managerphone === "") {
        alert("담당자 직통번호를 입력하세요.");
        return false;
      }

      // if (this.form.managermobile === "") {
      //   alert("담당자 휴대번호를 입력하세요.");
      //   return false;
      // }

      if (this.form.manageremail === "") {
        alert("담당자 이메일을 입력하세요.");
        return false;
      }

      ax.post_brandwrite(this.form, (flag, data) => {
        alert(data.message);
        if (flag) {
          this.$router.push({
            name: "BrandWrite",
            params: { password_yn: "y" },
          });
        } else {
          return false;
        }
      });
    },

    fileSelect() {
      this.form.filename = this.$refs.file.files[0];
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    handleFileChange(e) {
      this.targetFileName = e.target.files[0].name;
      this.form.filename = this.$refs.file.files[0];
    },
  },

  created() {
    this.step_chk();
    this.get_brandinfo();
  },
};
</script>
