<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page stats">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="filter-group row">
            <div class="col-12 col-sm-4 mb-2 mb-sm-0">
              <select aria-label="클럽타입선택" v-model="searchData.goodstype">
                <option value="1">드라이버</option>
                <option value="2">우드</option>
                <option value="3">유틸리티</option>
                <option value="4">아이언</option>
                <option value="5">웨지</option>
                <option value="6">퍼터</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <select
                aria-label="연월일 선택"
                v-model="searchData.datetype"
              >
                <option value="year">연별</option>
                <option value="month">월별</option>
                <option value="day">일별</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <date-picker
                v-model="searchData.searchdate"
                valueType="format"
                :disabled="searchData.datetype == 'year'"
                :type="searchData.datetype == 'month' ? 'year' : 'month'"
                placeholder="날짜선택"
                aria-label="날짜선택"
                class="w-100"
              ></date-picker>
            </div>
          </div>

          <div class="card rounded-0 mb-4">
            <div
              class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
            >
              모델별 매출
              <span class="align-self-center ms-auto price-unit"
                >(단위 : 천원)</span
              >
            </div>
            <div class="card-body">
              <GChart
                type="PieChart"
                :data="chartsData.salegoodsData"
                :options="chartsData.salegoodsOptions"
              />
            </div>
          </div>

          <b-table-simple responsive table-class="tfoot-type">
            <b-thead>
              <b-tr
                v-for="(val, key) in Object.entries(
                  saleData.salegoodslist
                ).slice(0, 1)"
                :key="'a' + key"
              >
                <b-th sticky-column>제품명</b-th>
                <b-th v-for="(val_, key_) in val[1]" :key="'a' + key_">
                  {{ val_[0] }}
                </b-th>
                <b-th>합계</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="Object.keys(saleData.salegoodslist).length">
              <b-tr
                v-for="(val, key) in saleData.salegoodslist"
                :key="'b' + key"
              >
                <b-td sticky-column class="goods-name">
                  {{ key }}
                </b-td>
                <b-td
                  v-for="(val_, key_) in val"
                  :key="'b' + key_"
                  class="text-price"
                >
                  {{ val_[1] && val_[1].salesum | comma("-") }}
                  <!-- <span
                    v-if="val_[1]"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
                <b-td class="text-price">
                  {{
                    saleData.total_salesum[key] &&
                    saleData.total_salesum[key].total_salesum | comma("-")
                  }}
                  <!-- <span
                    v-if="saleData.total_salesum[key]"
                    class="price-unit"
                    >천원
                  </span> -->
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="fw-normal bg-white">데이터가 없습니다.</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/stats/statsmainTab";
import ax from "@/api/stats";
import "@/filter/common";
import { GChart } from "vue-google-charts/legacy";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";

export default {
  name: "StatsMain",
  components: {
    location,
    tab,
    GChart,
    DatePicker,
  },
  data() {
    return {
      searchData: {
        datetype:
          this.$route.query.datetype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .datetype) ||
          "month",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        goodstype: 1,
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      chartsData: {
        salegoodsData: [],
        salegoodsOptions: {
          chart: {
            title: "모델별 매출",
          },
          chartArea: { height: "100%" },
        },
      },
      saleData: { salegoodsinfo: [], salegoodslist: [], total_salesum: {} },
      view_hide: null,
    };
  },
  methods: {
    get_salegoodslist() {
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });

      ax.get_salegoodslist(this.searchData, (flag, data = "") => {
        this.saleData.total_salesum = data.total_salesum;
        this.saleData.salegoodslist = {};
        this.chartsData.salegoodsData = [["모델별", "매출액"]];

        if (data.salegoodslist) {
          Object.keys(data.salegoodslist).forEach((k) => {
            let item = [];
            Object.keys(data.salegoodslist[k])
              .sort((a, b) => {
                return new Date(a) - new Date(b);
              })
              .forEach((_k) => {
                let datekey = _k;
                if (this.searchData.datetype === "year")
                  datekey =
                    parseInt(_k.substring(_k.length - 4, _k.length)) + "년";
                if (this.searchData.datetype === "month")
                  datekey =
                    parseInt(_k.substring(_k.length - 2, _k.length)) + "월";
                if (this.searchData.datetype === "day")
                  datekey =
                    parseInt(_k.substring(_k.length - 2, _k.length)) + "일";

                item.push([datekey, data.salegoodslist[k][_k] || ""]);
              });

            this.saleData.salegoodslist[k] = item;
          });
          for (let v of data.salegoodsinfo) {
            this.chartsData.salegoodsData.push([
              v.goodsname,
              parseInt(v.salesum) || 0,
            ]);
          }
        }
        console.log("salegoodsData", this.chartsData.salegoodsData);
      });
    },
  },
  created() {
    this.get_salegoodslist();
  },
  watch: {
    searchData: {
      handler() {
        this.$store.commit("setSearchData", {
          [this.$route.matched[0].name]: this.searchData,
        });
        this.get_salegoodslist();
      },
      deep: true,
    },
  },
};
</script>