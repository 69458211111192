<template>
  <div class="container-fluid">
    <location />

    <div class="page account-mng">
      <div class="d-flex flex-fill justify-content-center mt-5">
        <b-form @submit="onSubmit" class="text-center">
          <div class="d-flex flex-column mb-4">
            <div class="d-flex justify-content-center">
              <p class="icon-box rounded-circle bg-green">
                <span class="material-icons-round text-white">vpn_key</span>
              </p>
            </div>
            <h5 class="my-4">비밀번호 확인</h5>
            <p>본인확인을 위해 비밀번호를 입력해 주세요.</p>
          </div>
          <input
            type="password"
            class="form-control form-control-lg mb-2"
            placeholder="비밀번호 영문, 숫자 조합 8~30자"
            aria-label=""
            v-model="password"
          />
          <input
            class="btn btn-primary btn-lg w-100 mb-4"
            type="submit"
            value="확인"
          />
          <p class="small text-lightgray text-start lh-sm">
            비밀번호는 타인에게 노출되지 않도록 주의하시고, 주기적으로 변경하시는 것이 안전합니다.
          </p>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/account";

export default {
  name: "BrandPwdCheck",
  components: {
    location,
  },
  data() {
    return {
      password: "",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.password === "") {
        alert("비밀번호를 입력해 주세요.");
        return false;
      }

      ax.post_brandpwdcheck(this.password, (flag, data, message) => {
        if (flag) {
          // alert(message);
          this.$router.push({
            name: "BrandWrite",
            params: { password_yn: "y" },
          });
        } else {
          alert(message);
          return false;
        }
      });
    },
  },
};
</script>