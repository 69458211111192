<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page stats">
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-1"
          role="tabpanel"
          aria-labelledby="pills-1-tab"
        >
          <div class="filter-group row">
            <div class="col-6 col-sm-4">
              <select aria-label="연월 선택" v-model="searchData.datetype">
                <option value="year">연별</option>
                <option value="month">월별</option>
              </select>
            </div>
            <div class="col-6 col-sm-4">
              <date-picker
                v-model="searchData.searchdate"
                valueType="format"
                :disabled="searchData.datetype == 'year'"
                :type="searchData.datetype == 'month' ? 'year' : 'month'"
                placeholder="날짜선택"
                aria-label="날짜선택"
                class="w-100"
              ></date-picker>
            </div>
          </div>

          <div class="card rounded-0 mb-4">
            <div
              class="card-header d-flex rounded-0 lh-base fw-semibold text-black"
            >
              핸디캡
              <span class="align-self-center ms-auto price-unit text-secondary"
                >(단위 : 타수)</span
              >
            </div>
            <div class="card-body">
              <GChart
                :type="chartsData.chartsType"
                :data="chartsData.saleData"
                :options="chartsData.saletotalOptions"
              />
            </div>
          </div>

          <b-table-simple responsive table-class="tfoot-type">
            <b-thead>
              <b-tr
                v-for="(val, key) in Object.entries(saleData.salelist).slice(
                  0,
                  1
                )"
                :key="'a' + key"
              >
                <b-th sticky-column>타수</b-th>
                <b-th v-for="(val_, key_) in val[1]" :key="'a' + key_">
                  {{ val_[0] }}
                </b-th>
                <b-th>합계</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(val, key) in saleData.salelist" :key="'b' + key">
                <b-td sticky-column>{{ key }}</b-td>
                <b-td v-for="(val_, key_) in val" :key="'b' + key_">
                  {{ val_[1].cnt || "-" }}
                </b-td>
                <b-td>
                  {{
                    saleData.total_sum[key]
                      ? saleData.total_sum[key].total_sum
                      : "-"
                  }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/stats/statsmembertotalTab";
import ax from "@/api/stats";
import "@/filter/common";
import { GChart } from "vue-google-charts/legacy";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ko";

export default {
  name: "StatsMain",
  components: {
    location,
    tab,
    GChart,
    DatePicker,
  },
  data() {
    return {
      searchData: {
        datetype:
          this.$route.query.datetype ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .datetype) ||
          "month",

        searchdate:
          this.$route.query.searchdate ||
          (this.$store.state.searchData[this.$route.matched[0].name] &&
            this.$store.state.searchData[this.$route.matched[0].name]
              .searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        forder: "",
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      chartsData: {
        chartsType: "ColumnChart",
        saleData: [],
        saletotalOptions: {
          legend: { position: "bottom" },
        },
      },
      saleData: { saleinfo: [], salelist: {}, total_sum: {} },
      view_hide: null,
    };
  },
  methods: {
    get_memberhandicaplist() {
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });

      ax.get_memberhandicaplist(this.searchData, (flag, data = "") => {
        this.saleData.total_sum = data.total_sum;

        this.saleData.salelist = {};
        this.chartsData.chartsType = "ColumnChart";
        let _key = Object.keys(data.salelist);

        console.log("total_sum", _key);
        _key.forEach((k) => {
          let item = [];
          Object.keys(data.salelist[k])
            .sort((a, b) => {
              return new Date(a) - new Date(b);
            })
            .forEach((_k) => {
              let datekey = _k;
              if (this.searchData.datetype === "year")
                datekey =
                  parseInt(_k.substring(_k.length - 4, _k.length)) + "년";
              if (this.searchData.datetype === "month")
                datekey =
                  parseInt(_k.substring(_k.length - 2, _k.length)) + "월";

              item.push([datekey, data.salelist[k][_k] || ""]);
            });

          this.saleData.salelist[k] = item;
        });
        console.log("saleshoplist", this.saleData.salelist);

        _key = Object.keys(data.salelist).filter((k) => k !== "합계");

        console.log("total_sum", _key);
        this.chartsData.saleData = [["기간", ..._key]];
        Object.keys(data.saleinfo)
          .sort((a, b) => {
            return new Date(a) - new Date(b);
          })
          .forEach((k) => {
            let datekey = k;
            if (this.searchData.datetype === "year")
              datekey = parseInt(k.substring(k.length - 4, k.length)) + "년";
            if (this.searchData.datetype === "month")
              datekey = parseInt(k.substring(k.length - 2, k.length)) + "월";

            this.chartsData.saleData.push([
              datekey,
              ..._key.map((v) =>
                data.saleinfo[k][v] ? data.saleinfo[k][v] : 0
              ),
            ]);
          });
        if (!Object.keys(data.saleinfo).length) {
          this.chartsData.saleData = [["매장명", "매출액"]];
          this.chartsData.chartsType = "PieChart";
        }
        console.log("saleshopData", this.chartsData.saleData);
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.commit("setSearchData", {
        [this.$route.matched[0].name]: this.searchData,
      });
      this.get_memberhandicaplist();
    },
  },
  created() {
    this.get_memberhandicaplist();
  },
  watch: {
    searchData: {
      handler() {
        this.get_memberhandicaplist();
      },
      deep: true,
    },
  },
};
</script>
