<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <div class="filter-group row">
        <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
          <b-form @submit="onSubmit">
            <div class="d-flex">
              <select
                class="w-auto"
                aria-label="검색조건선택 "
                name="searchtype"
                v-model="searchData.searchtype"
              >
                <option value="B.fit_brand_nm">브랜드명</option>
              </select>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-truncate"
                  placeholder="검색어를 입력하세요."
                  aria-label="검색어를 입력하세요."
                  aria-describedby="button-addon"
                  name="searchname"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </b-form>
        </div>

        <!-- <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
          <div class="d-flex">
            <select aria-label="정렬">
              <option value="0" selected>신청일순</option>
              <option value="1">상태순</option>
              <option value="2">제품명순</option>
            </select>
            <select aria-label="목록개수">
              <option value="0" selected>10개 보기</option>
              <option value="1">20개 보기</option>
              <option value="2">30개 보기</option>
            </select>
          </div>
        </div> -->
      </div>

      <table class="table board" width="100%" cellspacing="0">
        <thead>
          <tr>
            <th class="col-auto d-lg-table-cell">로고</th>
            <th class="col-auto d-lg-table-cell">브랜드명</th>
            <th class="col-auto d-none d-lg-table-cell">담당부서</th>
            <th class="col-auto d-none d-lg-table-cell">담당자</th>
            <th class="col-auto d-none d-lg-table-cell">주소</th>
            <!-- <th class="col-auto d-none d-lg-table-cell">대표전화</th>
            <th class="col-auto d-none d-lg-table-cell">휴대전화</th>
            <th class="col-auto d-none d-lg-table-cell">팩스</th>
            <th class="col-auto d-none d-lg-table-cell">이메일</th> -->
            <th class="col-auto d-lg-table-cell">매장수</th>
            <th class="col-auto d-none d-lg-table-cell">피팅횟수</th>
            <th class="col-auto d-none d-lg-table-cell">누적매출</th>
            <th class="th-arrow"></th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in brandlist">
            <tr class="btn1" @click="toggle_tr(index)" :key="index">
              <td class="col-auto d-lg-table-cell">
                <img :src="item.brandimage | get_img" />
              </td>
              <td class="col-auto d-lg-table-cell">
                <router-link
                  :to="{
                    name: 'AdminBrandWrite',
                    params: {
                      brandno: item.brandno,
                    },
                  }"
                  class="pointer more"
                >
                  <label
                    class="brand-name"
                    >{{ item.brandname }}</label
                  ></router-link
                >
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.managerpart }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.managername }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.brandaddress }}
              </td>
              <!-- <td class="col-auto d-none d-lg-table-cell">
                {{ item.brandphone }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.brandmobile }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.brandfax }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.brandemail }}
              </td> -->
              <td class="col-auto d-lg-table-cell">{{ item.brandshopcnt }}</td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.brandfittingcnt }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.brandsalesum }}
              </td>
              <td class="td-arrow">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="col-auto d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="3">
                <div class="toggle-content">
                  <div class="row">
                    <div class="col-6">담당부서</div>
                    <div class="col-6">{{ item.managerpart }}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">담당자</div>
                    <div class="col-6">{{ item.managername }}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">주소</div>
                    <div class="col-6">{{ item.brandaddress }}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">대표전화</div>
                    <div class="col-6">{{ item.brandphone }}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">휴대전화</div>
                    <div class="col-6">{{ item.brandmobile }}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">이메일</div>
                    <div class="col-6">{{ item.brandemail }}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">팩스</div>
                    <div class="col-6">{{ item.brandfax }}</div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-6">매장수</div>
                    <div class="col-6">{{ item.brandshopcnt }}</div>
                  </div> -->
                  <div class="row">
                    <div class="col-6">피팅횟수</div>
                    <div class="col-6">{{ item.brandfittingcnt }}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">누적판매액</div>
                    <div class="col-6">{{ item.brandsalesum }}</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="12">데이터가 없습니다.</td>
          </tr>
        </tbody>
      </table>
      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class="mt-3"
        v-if="page_total > 1 && data_yn"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
      <div class="row regis-btn">
        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="primary"
            aria-label="브랜드 등록"
            @click="$router.push({ name: 'AdminBrandWrite' })"
            >브랜드 등록</b-button
          >
        </div>
      </div>

    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/brand";
import "@/filter/common";

export default {
  name: "Brands",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchtype: this.$route.query.searchtype || "B.fit_brand_nm",
        searchname: this.$route.query.searchname || "",
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      brandlist: [],
      rows: 10,
      brandlistcnt: 0,
      view_hide: null,
    };
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.get_adminbrandlist();
    },
  },
  methods: {
    get_adminbrandlist() {
      ax.get_adminbrandlist(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          if (data.pagination.brandlistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }
          this.brandlist = data.brandlist;
          this.brandlistcnt = data.pagination.brandlistcnt;
          this.total_cnt = data.pagination.brandlistcnt;
          this.page_total = Math.ceil(data.pagination.brandlistcnt / this.rows);
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchtype: this.searchData.searchtype,
            searchname: this.searchData.searchname,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}&searchname=${this.searchData.searchname}&searchtype=${this.searchData.searchtype}`;
    },

    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.searchData.pageno = 1;
    this.get_adminbrandlist();
  },
};
</script>

<style></style>
