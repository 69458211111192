<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <b-form @submit="onSubmit">
        <section class="adv mt-0">
          <div class="row g-0">
            <div class="col-md-5" v-if="adno">
              <div
                class="col adv-item adv-video has-item"
                v-if="form.admoviename && form.adtype == '1'"
              >
                <video class="ratio" controls autoplay muted>
                  <source
                    type="video/mp4"
                    :src="form.admovienametxt | get_img"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="col adv-item adv-video" v-else></div>
            </div>
            <div class="col-md-5" v-else>
              <div
                class="col adv-item adv-video adv-item-selected"
                v-if="form.adtype == '1'"
              ></div>
              <div
                class="col adv-item adv-video"
                v-else
                @click="change_type(1)"
              ></div>
            </div>

            <div class="col-md-7 flex-wrap" v-if="adno">
              <div
                class="col-12 adv-item adv-title justify-content-center mb-2 has-item"
                v-if="form.adimagename && form.adtype == '2'"
              >
                <img
                  class="align-self-center"
                  :src="form.adimagenametxt | get_img"
                />
              </div>
              <div
                class="col-12 adv-item adv-title justify-content-center mb-2"
                v-else
              ></div>

              <div class="row g-0 flex-grow-1">
                <div
                  class="col adv-item adv-goods flex-sm-column has-item"
                  v-if="form.adimagename && form.adtype == '3'"
                >
                  <img
                    :src="form.adimagenametxt | get_img"
                  />
                  <p class="goods-name" v-if="form.adimagename">
                    <span>{{ form.adtitle }}</span>
                  </p>
                </div>
                <div
                  class="col adv-item adv-goods flex-sm-column"
                  v-else
                ></div>

                <div
                  class="col adv-item adv-goods mx-0 mx-sm-2 flex-sm-column has-item"
                  v-if="form.adimagename && form.adtype == '3'"
                >
                  <img
                    :src="form.adimagenametxt | get_img"
                  />
                  <p class="goods-name" v-if="form.adimagename">
                    <span>{{ form.adtitle }}</span>
                  </p>
                </div>
                <div
                  class="col adv-item adv-goods mx-0 mx-sm-2 flex-sm-column"
                  v-else
                ></div>

                <div
                  class="col adv-item adv-goods flex-sm-column has-item"
                  v-if="form.adimagename && form.adtype == '3'"
                >
                  <img
                    :src="form.adimagenametxt | get_img"
                  />
                  <p class="goods-name" v-if="form.adimagename">
                    <span>{{ form.adtitle }}</span>
                  </p>
                </div>
                <div
                  class="col adv-item adv-goods flex-sm-column"
                  v-else
                ></div>
              </div>
              
            </div>
            <div class="col-md-7 flex-wrap" v-else>
              <div
                class="col-12 adv-item adv-title justify-content-center mb-2 adv-item-selected"
                v-if="form.adtype == '2'"
              ></div>
              <div
                class="col-12 adv-item adv-title justify-content-center mb-2"
                v-else
                @click="change_type(2)"
              ></div>

              <div class="row g-0 flex-grow-1">
                <div
                  class="col adv-item adv-goods flex-sm-column adv-item-selected"
                  v-if="form.adtype == '3'"
                ></div>
                <div
                  class="col adv-item adv-goods flex-sm-column"
                  v-else
                  @click="change_type(3)"
                ></div>

                <div
                  class="col adv-item adv-goods mx-0 mx-sm-2 flex-sm-column adv-item-selected"
                  v-if="form.adtype == '3'"
                ></div>
                <div
                  class="col adv-item adv-goods mx-0 mx-sm-2 flex-sm-column"
                  v-else
                  @click="change_type(3)"
                ></div>

                <div
                  class="col adv-item adv-goods flex-sm-column adv-item-selected"
                  v-if="form.adtype == '3'"
                ></div>
                <div
                  class="col adv-item adv-goods flex-sm-column"
                  v-else
                  @click="change_type(3)"
                ></div>
              </div>
            </div>
          </div>
        </section>
        <!-- //광고등록 -->
        <section>
          <h6 class="sub-title">{{ form.adtypetxt }} 등록</h6>
          <div class="row g-0 table-type header-col">
            <div class="col-sm-3">
              <span class="required">광고종류</span>
            </div>
            <div class="col-sm-9">
              {{ form.adtypetxt }}
            </div>

            <div class="col-sm-3">
              <span class="required">구분</span>
            </div>
            <div class="col-sm-9">
              <select name="goodstype" id="goodstype" v-model="form.goodstype">
                <option value="1">드라이버</option>
                <option value="2">우드</option>
                <option value="3">유틸리티</option>
                <option value="4">아이언</option>
                <option value="5">웨지</option>
                <option value="6">퍼터</option>
              </select>
            </div>

            <div class="col-sm-3">
              <span class="required">제목</span>
            </div>
            <div class="col-sm-9">
              <b-form-input
                type="text"
                placeholder="제목"
                name="adtitle"
                id="adtitle"
                v-model="form.adtitle"
                required
              ></b-form-input>
            </div>

            <div class="col-sm-3" v-if="adlink_yn === 'y'">
              <span class="required">링크</span>
            </div>
            <div class="col-sm-9" v-if="adlink_yn === 'y'">
              <b-form-input
                type="text"
                placeholder="링크"
                name="adlink"
                id="adlink"
                v-model="form.adlink"
                required
              ></b-form-input>
            </div>

            <div class="col-sm-3">
              <span class="required">대표 이미지</span>
            </div>
            <div class="col-sm-9">
              <b-form-file
                v-model="file1"
                ref="file1"
                :state="Boolean(file1)"
                placeholder="파일을 선택하거나 여기에 드롭하세요."
                drop-placeholder="여기에 드롭하세요."
                @change="fileSelect"
              >
              </b-form-file>
              <p
                class="mt-2 text-subscript text-truncate more"
                @click="$bvModal.show('modal-img-thumbnail')"
                v-if="form.adimagenametxt"
              >
                {{ form.adimagenametxt }}
              </p>
            </div>

            <div class="col-sm-3" v-if="admovie_yn === 'y'">
              <span class="required">동영상</span>
            </div>
            <div class="col-sm-9" v-if="admovie_yn === 'y'">
              <b-form-file
                v-model="file2"
                ref="file2"
                :state="Boolean(file2)"
                placeholder="파일을 선택하거나 여기에 드롭하세요."
                drop-placeholder="여기에 드롭하세요."
                @change="movieSelect"
              >
              </b-form-file>
              <p
                class="mt-2 text-subscript text-truncate more"
                @click="$bvModal.show('modal-mov-thumbnail')"
                v-if="form.admovienametxt"
              >
                {{ form.admovienametxt }}
              </p>
            </div>
          </div>
        </section>
        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            aria-label="취소"
            @click="$router.go(-1)"
            >취소</b-button
          >
          <b-button type="submit" size="xl" variant="primary" aria-label="등록">
            {{ adno ? "수정" : "등록" }}</b-button
          >
        </div>
      </b-form>

      <b-modal
        id="modal-img-thumbnail"
        size="lg"
        centered
        scrollable
        body-class="d-flex justify-content-center"
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">이미지</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <img :src="form.adimagenametxt | get_img" class="mw-100 h-100" />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            aria-label="닫기"
            @click="cancel()"
          >
            닫기
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-mov-thumbnail"
        size="lg"
        centered
        scrollable
        body-class="d-flex justify-content-center"
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">동영상</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <video class="ratio" controls autoplay muted>
            <source type="video/mp4" :src="form.admovienametxt | get_img" />
            Your browser does not support the video tag.
          </video>
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            aria-label="닫기"
            @click="cancel()"
          >
            닫기
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import location from "@/components/Location";
import ax from "@/api/ad";
import "@/filter/common";

export default {
  name: "AdWrite",
  data() {
    return {
      form: {
        brandno: "0",
        adtitle: "",
        adlink: "",
        adtype: this.$route.query.adtype || 1,
        adtypetxt: "동영상 광고",
        goodstype: 1,
        adimagename: "",
        adimagenametxt: "",
        admoviename: "",
        admovienametxt: "",
      },
      file1: null,
      file2: null,

      adno: this.$route.params.adno || "",
      adlink_yn: "n",
      admovie_yn: "n",

      mainbrandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
    };
  },
  components: {
    location,
  },

  methods: {
    getAdview() {
      // alert(this.form.adtype);
      this.change_type(this.form.adtype);
      if (this.mainbrandno) {
        this.form.brandno = this.mainbrandno;
      }
      if (this.adno) {
        ax.get_adview(this.adno, {}, (flag, data) => {
          console.log(flag, data);
          this.form.adtitle = data.adinfo.adtitle;
          this.form.adlink = data.adinfo.adlink;
          this.form.adtype = data.adinfo.adtype;
          this.form.goodstype = data.adinfo.goodstype;
          this.form.adimagename = data.adinfo.adimagename;
          this.form.adimagenametxt = data.adinfo.adimagename;
          this.form.admoviename = data.adinfo.admoviename;
          this.form.admovienametxt = data.adinfo.admoviename;

          if (sessionStorage.getItem("isAdmin") == "1") {
            if (
              data.adinfo.brandno &&
              this.mainbrandno != data.adinfo.brandno
            ) {
              this.$store.commit("setBrandno", data.adinfo.brandno);
              window.location.reload(true);
            }
            this.form.brandno = data.adinfo.brandno;
          }

          if (!flag) {
            alert(data);
          }
        });
      }
    },
    onSubmit(event) {
      event.preventDefault();

      if (this.form.adtitle === "") {
        alert("제목을 입력하세요.");
        return false;
      }
      if (this.form.adtype === "2" || this.form.adtype === "3") {
        if (this.form.adlink === "") {
          alert("링크를 입력하세요.");
          return false;
        }
      }
      if (this.form.adtitle === "") {
        alert("제목을 입력하세요.");
        return false;
      }
      if (this.form.adtype === "2" || this.form.adtype === "3") {
        if (this.form.adlink === "") {
          alert("링크를 입력하세요.");
          return false;
        }
      }

      ax.post_adwrite(this.form, this.adno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          alert(data.message);
          // this.$router.push({
          //   path: "/ad/movieadlist/",
          // });
        } else {
          alert(data.message);
        }
      });
    },
    fileSelect(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.adimagename = e.dataTransfer.files[0];
      } else {
        this.form.adimagename = e.target.files[0];
      }
    },
    movieSelect(e) {
      // alert(e.dataTransfer.files[0]);
      if (e.dataTransfer) {
        this.form.admoviename = e.dataTransfer.files[0];
      } else {
        this.form.admoviename = e.target.files[0];
      }
    },
    change_type(adtypes) {
      if (!adtypes) adtypes = this.form.adtype;

      if (adtypes == 1) {
        this.form.adtype = 1;
        this.form.adtypetxt = "동영상 광고";
        this.adlink_yn = "n";
        this.admovie_yn = "y";
      } else if (adtypes == 2) {
        this.form.adtype = 2;
        this.form.adtypetxt = "타이틀 광고";
        this.adlink_yn = "y";
        this.admovie_yn = "n";
      } else if (adtypes == 3) {
        this.form.adtype = 3;
        this.form.adtypetxt = "제품 광고";
        this.adlink_yn = "y";
        this.admovie_yn = "n";
      } else {
        this.form.adtype = 1;
        this.form.adtypetxt = "동영상 광고";
        this.adlink_yn = "n";
        this.admovie_yn = "y";
      }
    },
  },
  created() {
    this.getAdview();
  },
};
</script>
