<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <section>
        <h6 class="sub-title">구매자 정보</h6>
        <div
          class="row g-0 row-cols-2 row-cols-md-4 table-type header-col"
        >
          <div
            class="col col-lg-1 justify-content-center px-0 border-bottom border-lg-0"
          >
            닉네임
          </div>
          <div class="col border-bottom border-lg-0 d-flex">
            <div class="align-self-center">
              <MemberInfo
                :nickname="fittingsaleview.memberinfo.membername"
                :memberno="fittingsaleview.memberinfo.memberno"
              />
            </div>
            <!-- <span class="material-icons-outlined ms-1 fs-6">help_outline</span> -->
          </div>
          <div
            class="col col-lg-1 justify-content-center px-0 border-bottom border-lg-0"
          >
            주문일
          </div>
          <div class="col border-bottom border-lg-0 align-self-center">
            <span class="text-date">
              {{ fittingsaleview.memberinfo.orderdate || "-" }}
            </span>
          </div>
          <div class="col col-lg-1 justify-content-center px-0 border-bottom-0">
            매장명
          </div>
          <div class="col-md-9 col-lg align-self-center">
            <span class="shop-name">{{
              fittingsaleview.memberinfo.shopname
            }}</span>
          </div>
        </div>
      </section>

      <section>
        <h6 class="sub-title">판매 제품</h6>
        <div class="card m-0 p-0 bg-transparent border-0 rounded-0">
          <div class="row g-0 table-type header-col">
            <div class="col-sm-3">
              <div
                class="d-flex flex-fill justify-content-center h-100 bg-white"
              >
                <img
                  :src="fittingsaleview.goodsinfo.fit_goods_image1 | get_img"
                  class="img-fluid rounded-0"
                />
              </div>
            </div>
            <div class="col-sm-9 d-flex flex-column align-items-stretch">
              <div class="card-body px-0 px-sm-3">
                <h4 class="card-title">
                  {{ fittingsaleview.goodsinfo.fit_goods_nm }}
                </h4>
                <div class="row g-0 row-cols-1 row-cols-sm-2">
                  <div
                    class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 fw-bold text-black"
                  >
                    소비자가
                  </div>
                  <div class="col-sm-8 py-0 py-sm-2">
                    <h5 class="text-price">
                      &#8361;
                      {{ fittingsaleview.answerinfo.fit_sale_price | comma }}
                    </h5>
                  </div>

                  <div
                    class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                  >
                    매장공급가
                  </div>
                  <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                    <h5 class="text-price">
                      &#8361;
                      {{ fittingsaleview.answerinfo.fit_shop_price | comma }}
                    </h5>
                  </div>

                  <div
                    class="col-sm-4 mt-2 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                  >
                    엑스골프공급가
                  </div>
                  <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                    <h5 class="text-price">
                      &#8361;
                      {{ fittingsaleview.answerinfo.fit_supply_price | comma }}
                    </h5>
                  </div>

                  <div
                    class="col-sm-4 mt-3 mt-sm-0 py-0 py-sm-2 border-top-sm fw-bold text-black"
                  >
                    구매혜택
                  </div>
                  <div class="col-sm-8 py-0 py-sm-2 border-top-sm">
                    {{ fittingsaleview.goodsinfo.fit_goods_info }}
                    <!-- 미등록 -->
                  </div>
                </div>
              </div>
              <!-- //card-body -->
              <!-- <div
                        class="card-footer px-0 px-sm-3 py-0 bg-transparent border-0 rounded-0"
                      >
                        <div
                          class="btn-bottom-wrapper mt-2 mb-4 mt-sm-0 mb-sm-3"
                        >
                          <b-button
                            size="md"
                            variant="primary"
                            aria-label="제품구매"
                          >
                            제품구매
                          </b-button>
                        </div>
                      </div> -->
              <!-- //card-footer -->
            </div>
          </div>
        </div>
      </section>

      <!-- 제품 상세 정보 -->
      <section>
        <h6 class="sub-title">제품 상세 정보</h6>
        <div class="row g-0 table-type header-col">
          <img
            v-if="fittingsaleview.goodsinfo.fit_goods_image2"
            :src="fittingsaleview.goodsinfo.fit_goods_image2 | get_img"
            class="img-fluid rounded-0"
          />
          <img
            v-if="fittingsaleview.goodsinfo.fit_goods_image3"
            :src="fittingsaleview.goodsinfo.fit_goods_image3 | get_img"
            class="img-fluid rounded-0"
          />
        </div>
      </section>

      <!-- 스펙 -->
      <section class="reg-spec">
        <h6 class="sub-title">스펙</h6>
        <b-table-simple responsive table-class="border-0">
          <b-thead>
            <b-tr>
              <b-th sticky-column>클럽</b-th>
              <b-th
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubnotype' + idx"
              >
                {{
                  fittingsaleview.clubtypelist.find(
                    (v) => v.clubtype_code == val.fit_club_nm
                  ).clubtype_code_name
                }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-th sticky-column>로프트(&deg;)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubloft' + idx"
              >
                {{ val.fit_club_loft }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>라이(&deg;)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clublie' + idx"
              >
                {{ val.fit_club_lie }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>헤드볼륨(㎤)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubheadvolume' + idx"
              >
                {{ val.fit_club_headvolume }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>FP(mm)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubfp' + idx"
                >{{ val.fit_club_fp }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column>길이(Inch)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clublength' + idx"
              >
                {{ val.fit_club_length }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th sticky-column rowspan="5">
                <div class="sticky-column-rowspan">
                  <div>밸런스</div>
                  <div class="flex-column">
                    <div
                      v-if="
                        fittingsaleview.answerinfo.fit_balance1 ==
                        fittingsaleview.answerinfo.fit_goods_balance
                      "
                    >
                      {{
                        fittingsaleview.answerinfo.fit_balance1 | get_balance
                      }}
                    </div>
                    <div
                      v-if="
                        fittingsaleview.answerinfo.fit_balance2 ==
                        fittingsaleview.answerinfo.fit_goods_balance
                      "
                    >
                      {{
                        fittingsaleview.answerinfo.fit_balance2 | get_balance
                      }}
                    </div>
                    <div
                      v-if="
                        fittingsaleview.answerinfo.fit_balance3 ==
                        fittingsaleview.answerinfo.fit_goods_balance
                      "
                    >
                      {{
                        fittingsaleview.answerinfo.fit_balance3 | get_balance
                      }}
                    </div>
                    <div
                      v-if="
                        fittingsaleview.answerinfo.fit_balance4 ==
                        fittingsaleview.answerinfo.fit_goods_balance
                      "
                    >
                      {{
                        fittingsaleview.answerinfo.fit_balance4 | get_balance
                      }}
                    </div>
                    <div
                      v-if="
                        fittingsaleview.answerinfo.fit_balance5 ==
                        fittingsaleview.answerinfo.fit_goods_balance
                      "
                    >
                      {{
                        fittingsaleview.answerinfo.fit_balance5 | get_balance
                      }}
                    </div>
                  </div>
                </div>
              </b-th>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle1' + idx"
                  v-if="
                    fittingsaleview.answerinfo.fit_balance1 ==
                    fittingsaleview.answerinfo.fit_goods_balance
                  "
                >
                  {{ val.fit_club_balance1 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle2' + idx"
                  v-if="
                    fittingsaleview.answerinfo.fit_balance2 ==
                    fittingsaleview.answerinfo.fit_goods_balance
                  "
                >
                  {{ val.fit_club_balance2 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle3' + idx"
                  v-if="
                    fittingsaleview.answerinfo.fit_balance3 ==
                    fittingsaleview.answerinfo.fit_goods_balance
                  "
                >
                  {{ val.fit_club_balance3 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle4' + idx"
                  v-if="
                    fittingsaleview.answerinfo.fit_balance4 ==
                    fittingsaleview.answerinfo.fit_goods_balance
                  "
                >
                  {{ val.fit_club_balance4 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <template
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
              >
                <b-td
                  :key="'p_clubflextitle5' + idx"
                  v-if="
                    fittingsaleview.answerinfo.fit_balance5 ==
                    fittingsaleview.answerinfo.fit_goods_balance
                  "
                >
                  {{ val.fit_club_balance5 }}
                </b-td>
              </template>
            </b-tr>
            <b-tr>
              <b-th sticky-column class="border-0">총중량(g)</b-th>
              <b-td
                v-for="(val, idx) in fittingsaleview.answerinfo.ansclublist"
                :key="'p_clubweight' + idx"
              >
                {{ val.fit_club_weight }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </section>

      <!-- 샤프트 & 그립 -->
      <section class="reg-etc">
        <div class="row gx-4 gy-5 border-0">
          <div class="col-lg-6 d-flex flex-column">
            <h6 class="sub-title">샤프트</h6>
            <div
              class="card h-100"
              v-if="fittingsaleview.shaftinfo.fit_goods_shaft_pk"
            >
              <div class="card-body p-0">
                <p class="card-title">
                  {{ fittingsaleview.shaftinfo.fit_goods_shaft_nm }}
                  /
                  {{
                    fittingsaleview.shaftinfo.fit_shaft_material
                      | get_shaft_material
                  }}
                </p>
                <img
                  :src="
                    fittingsaleview.shaftinfo.fit_goods_shaft_image1 | get_img
                  "
                  class="card-img-top rounded-0"
                  alt="샤트프"
                />
              </div>
              <div class="card-footer">
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">강도</th>
                      <th scope="col">무게(g)</th>
                      <th scope="col">토크(˚)</th>
                      <th scope="col">킥포인트</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{
                          fittingsaleview.shaftinfo.fit_goods_shaft_balance
                            | get_balance
                        }}
                      </td>
                      <td>
                        {{ fittingsaleview.shaftinfo.fit_goods_shaft_weight }}
                      </td>
                      <td>
                        {{ fittingsaleview.shaftinfo.fit_goods_shaft_torque }}
                      </td>
                      <td>
                        {{
                          fittingsaleview.shaftinfo.fit_goods_shaft_kickpoint
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="text-center" v-else>데이터가 없습니다.</div>
          </div>
          <div class="col-lg-6 d-flex flex-column">
            <h6 class="sub-title">그립</h6>
            <div class="card h-100" v-if="fittingsaleview.gripinfo">
              <div class="card-body p-0">
                <p class="card-title">
                  {{ fittingsaleview.gripinfo.fit_goods_grip_nm }}}
                </p>
                <img
                  :src="
                    fittingsaleview.gripinfo.fit_goods_grip_image1 | get_img
                  "
                  class="card-img-top rounded-0"
                  alt="그립"
                />
              </div>
              <div class="card-footer">
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">규격</th>
                      <th scope="col">무게(g)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ fittingsaleview.gripinfo.fit_goods_grip_standard }}
                      </td>
                      <td>
                        {{ fittingsaleview.gripinfo.fit_goods_grip_weight }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="text-center" v-else>데이터가 없습니다.</div>
          </div>
        </div>
      </section>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          aria-label="목록"
          @click="
            $router.push({
              name: 'FittingSaleList',
              query: { goodstype: goodstype },
            })
          "
          >목록
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/sale";
import "@/filter/common";

export default {
  name: "FittingSaleView",
  components: {
    location,
  },
  data() {
    return {
      brandno: "0",
      fittinganswerno: this.$route.params.fittinganswerno,
      fittingsaleview: {
        goodsinfo: {},
        memberinfo: {},
        answerinfo: [],
        gripinfo: {},
        shaftinfo: {},
        clubtypelist: [],
      },
      goodstype: this.$route.query.goodstype || 0,

      mainbrandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
    };
  },
  methods: {
    get_saleview() {
      if (this.mainbrandno) {
        this.brandno = this.mainbrandno;
      }
      ax.get_saleview(this.fittinganswerno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          this.fittingsaleview = data.fittingsaleview;

          if (sessionStorage.getItem("isAdmin") == "1") {
            if (
              data.fittingsaleview.answerinfo.brandno &&
              this.mainbrandno != data.fittingsaleview.answerinfo.brandno
            ) {
              this.$store.commit(
                "setBrandno",
                data.fittingsaleview.answerinfo.brandno
              );
              // window.location.reload(true);
            }
            this.brandno = data.fittingsaleview.answerinfo.brandno;
          }
        } else {
          alert(data.message);
        }
      });
    },
  },
  created() {
    this.get_saleview();
  },
};
</script>
