<template>
  <div class="tab-main">
    <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: tab - 1,
      }"
      class="swiper mySwiper"
    >
      <swiper-slide :class="{ active: tab == 0 }">
        <div
          @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: '0' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '0',
          }"
        >
          전체
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 1 }">
        <div
          @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: '1' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '1',
          }"
        >
          드라이버
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 2 }">
        <div
          @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: '2' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '2',
          }"
        >
          우드
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 3 }">
        <div
          @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: '3' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '3',
          }"
        >
          유티리티
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 4 }">
        <div
          @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: '4' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '4',
          }"
        >
          아이언
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 5 }">
        <div
          @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: '5' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '5',
          }"
        >
          웨지
        </div>
      </swiper-slide>
      <swiper-slide :class="{ active: tab == 6 }">
        <div
          @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: '6' },
              })
              .catch(() => {})
          "
          :class="{
            active: $route.params.goodstype === '6',
          }"
        >
          퍼터
        </div>
      </swiper-slide>
      <!-- <swiper-slide :class="{ active: tab == 7 }">
        <div
        @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: '7' },
              })
              .catch(() => {})
          "
          :class="{
            active:
              $route.params.goodstype  === '7'
          }"
        >
          기타
        </div>
      </swiper-slide>                -->
    </swiper>

    <!-- <swiper
      :options="{
        slidesPerView: 'auto',
        freeMode: true,
        initialSlide: tab - 1,
      }"
      class="swiper d-lg-none"
    >
      <swiper-slide :class="{ active: tab == index }" :key="index" v-for="(item, index) in 8">
        <div
        @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: index },
              })
              .catch(() => {})
          "
          :class="{
            active:
              $route.params.goodstype  === index
          }"
        >
         {{ club_type(index) }} {{(index) }}
        </div>
      </swiper-slide>
    </swiper> -->
    <!-- <ul class="nav nav-pills d-none d-lg-flex" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation" :key="index" v-for="(item, index) in 8">
        <button
          class="nav-link"
          id="pills-1-tab"
          data-bs-toggle="pill"
          data-bs-target="pills-1"
          type="button"
          role="tab"
          aria-controls="pills-1"
          aria-selected="true"
          @click="
            $router
              .push({
                name: `${'AdList' + $route.meta.adtype}`,
                params: { goodstype: index},
              })
              .catch(() => {})
          "
          :class="{
            active:
              $route.params.goodstype  === index
          }"
        >
          {{ club_type(index) }}
        </button>
      </li>

    </ul>      -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "Tab",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      if (
        this.$route.name === "AdList1" ||
        this.$route.name === "AdList2" ||
        this.$route.name === "AdList3"
      ) {
        // alert(this.$route.params.goodstype);
        if (this.$route.params.goodstype) {
          return parseInt(this.$route.params.goodstype);
        } else {
          return 7;
        }
      } else {
        return 0;
      }
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mixins: [myMixin],
};
</script>
