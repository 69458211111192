<template>
  <div class="container-fluid">
    <location />
    <tab />
    <div class="page">
      <b-form @submit="onSubmit">
        <div class="filter-group row">
          <div class="col-sm-auto mb-2 mb-sm-0 col-sm-6 col-md-5">
            <div class="d-flex">
              <select
                class="w-auto"
                aria-label="검색조건선택"
                v-model="searchData.searchtype"
              >
                <option value="fit_goods_grip_nm">모델명</option>
              </select>
              <div class="input-group flex-nowrap">
                <input
                  type="text"
                  class="form-control text-truncate"
                  placeholder="검색어를 입력하세요."
                  aria-label="검색어를 입력하세요."
                  aria-describedby="button-addon"
                  v-model="searchData.searchname"
                />
                <button
                  class="btn btn-primary btn-search"
                  type="submit"
                  id="button-addon"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-sm-auto ms-sm-auto col-sm-5 col-md-4">
            <div class="d-flex">
              <select aria-label="정렬" v-model="searchData.findex">
                <option value="reg_date" selected>등록순</option>
                <option value="fit_goods_grip_nm">모델명순</option>
              </select>
              <select aria-label="목록개수" v-model="searchData.pagesize">
                <option value="20" selected>20개 보기</option>
                <option value="40">40개 보기</option>
                <option value="60">60개 보기</option>
              </select>
            </div>
          </div>
        </div>
      </b-form>

      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 g-lg-4" v-if="data_yn">
        <div
          class="col"
          :key="index"
          v-for="(item, index) in griplist"
        >
          
            <router-link
              :to="{
                name: 'GripWrite',
                params: { gripno: item.gripno },
                query: {
                  searchtype: searchData.searchtype,
                  searchname: searchData.searchname,
                },
              }"
              class="card h-100 rounded-0 view-zoom-in"
            >
              <div class="card-body d-flex justify-content-center align-items-center overflow-hidden m-2 py-5">
                <img :src="grip_img(index)" class="rounded-0" alt="Grips">
              </div>
              <div class="card-footer p-3 border-0 bg-light">
                <h6 class="mb-0 font-montserrat text-truncate">{{ item.griptitle }}</h6>
              </div>
            </router-link>
          
        </div>
      </div>
      <div class="row g-0 border-top-dgray border-bottom text-center" v-else>
        <div class="col m-5">데이터가 없습니다.</div>
      </div>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation example"
        class=""
        v-if="page_total > 1 && data_yn"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/goods/Tab";
import ax from "@/api/goods";
import "@/filter/common";

export default {
  name: "gripList",
  components: {
    location,
    tab,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        pagesize: parseInt(this.$route.query.pagesize) || 20,
        findex: this.$route.query.findex || "reg_date",
        searchname: this.$route.query.searchname || "",
        searchtype: this.$route.query.searchtype || "fit_goods_grip_nm",
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      griplist: [],
      // rows: 10,
      griplistcnt: 0,
    };
  },
  methods: {
    getGriplist() {
      ax.get_griplist(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          if (data.pagination.griplistcnt <= 0) {
            this.data_yn = false;
            return false;
          } else {
            this.data_yn = true;
          }

          this.griplist = data.griplist;
          this.griplistcnt = data.pagination.griplistcnt;
          this.total_cnt = data.pagination.griplistcnt;
          this.page_total = Math.ceil(
            data.pagination.griplistcnt / this.searchData.pagesize
          );
        } else {
          this.page_total = 0;
          this.data_yn = false;
          this.$notify({
            group: "alert",
            type: "warn",
            text: data,
            duration: 5000,
            speed: 1000,
          });
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router
        .push({
          query: {
            searchtype: this.searchData.searchtype,
            searchname: this.searchData.searchname,
            pagesize: this.searchData.pagesize,
            findex: this.searchData.findex,
            brandno: this.searchData.brandno,
          },
        })
        .catch(() => {});
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    grip_img(index) {
      if (
        this.griplist[index].gripimagepath !== null &&
        this.griplist[index].gripimagepath !== ""
      ) {
        return (
          process.env.VUE_APP_S3_BUCKET_URL +
          "/" +
          this.griplist[index].gripimagepath
        );
      } else {
        return "/img/no-img.jpg";
      }
    },
  },
  created() {
    this.getGriplist();
  },
  watch: {
    "$route.query": function () {
      this.searchData.pageno = parseInt(this.$route.query.page) || 1;
      this.searchData.pagesize = parseInt(this.$route.query.pagesize) || 20;
      this.getGriplist();
    },
    "searchData.pagesize": function () {
      this.searchData.pageno = 1;
      this.getGriplist();
    },
    "searchData.findex": function () {
      this.searchData.pageno = 1;
      this.getGriplist();
    },
  },
};
</script>
