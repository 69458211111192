<template>
  <div class="container-fluid">
    <location />

    <div class="board_view page ps-5 pe-5">
      <div>
        <div class="col-12 mt-5 mb-4">
          <h2>
            [{{ noticeinfo.category | get_custormer_category }}]
            {{ noticeinfo.noticetitle }}
          </h2>
        </div>

        <article class="article-header row pt-3 pb-3 bg-light">
          <div class="d-flex flex-wrap">
            <div class="col-12 col-sm-6 color-black ps-2">
              <!-- <span
                class="icon badge"
                :class="[
                  { 'fill-purple': noticeinfo.category === '공지사항' },
                  { 'fill-blue': noticeinfo.category === 'A/S' },
                  { 'fill-purple': noticeinfo.category === '개선사항' },
                  { 'fill-blue': noticeinfo.category === '기타' },
                ]"
              >
                {{ noticeinfo.category }}
              </span> -->
              <span class="text-black"> 매장명 :</span> {{ noticeinfo.writer }}
            </div>
            <div class="d-flex col-12 col-sm-6 pe-2">
              <div class="ms-auto">
                <span class="me-2">
                  <i class="material-icons">date_range </i>
                  {{ noticeinfo.regdate }}
                </span>
                <span>
                  <i class="material-icons">visibility</i>
                  {{ noticeinfo.viewcnt | comma }}
                </span>
              </div>
            </div>
          </div>
        </article>
        <article class="article-attach" v-if="noticeinfo.as_filename">
          첨부파일 :
          <a :href="noticeinfo.as_download_link">
            <i class="material-icons-outlined">file_download</i>
            <span>{{ noticeinfo.as_filename }}</span>
          </a>
        </article>

        <article class="article-body" v-if="noticeinfo.category === '1'">
          <span v-html="noticeinfo.content"></span>
        </article>
        <article class="article-body" v-else>
          <span v-html="noticeinfo.content"></span>
        </article>
        <b-form v-if="noticeinfo.status === '완료'"
          ><!-- 하단답변부분 -->
          <div class="row g-0 table-type header-col">
            <div class="col-sm-3">
              <span class="required">예상 청구 비용</span>
            </div>
            <div class="col-sm-9">
              {{ noticeinfo.answerprice }}
            </div>

            <div class="col-sm-3">
              <span class="required">답변</span>
            </div>
            <div class="col-sm-9">
              <span v-html="noticeinfo.answercontent"></span>
            </div>

            <div class="col-sm-3">
              <span>답변 파일</span>
            </div>
            <div class="col-sm-9">
              <article class="article-attach" v-if="noticeinfo.as_ans_filename">
                <a :href="noticeinfo.as_ans_download_link">
                  <i class="material-icons-outlined">file_download</i>
                  <span>{{ noticeinfo.as_ans_filename }}</span>
                </a>
              </article>
            </div>
          </div>
        </b-form>

        <article class="article-footer">
          <div class="prev" :class="!next_post.fit_as_pk && 'no-data'">
            <template v-if="next_post.fit_as_pk">
              <router-link
                :to="{
                  name: 'NoticeView',
                  params: { noticeno: next_post.fit_as_pk },
                }"
                class="text-truncate"
              >
                <i class="material-icons-outlined"> expand_less </i>
                <span>{{ next_post.title }}</span>
              </router-link>
            </template>
            <template v-else>
              <i class="material-icons-outlined"> expand_less </i>
              <span>이전 글이 없습니다.</span>
            </template>
          </div>
          <div class="next" :class="!prev_post.fit_as_pk && 'no-data'">
            <template v-if="prev_post.fit_as_pk">
              <router-link
                :to="{
                  name: 'NoticeView',
                  params: { noticeno: prev_post.fit_as_pk },
                }"
                class="text-truncate"
              >
                <i class="material-icons-outlined"> expand_more </i>
                <span>{{ prev_post.title }}</span>
              </router-link>
            </template>
            <template v-else>
              <i class="material-icons-outlined"> expand_more </i>
              <span>다음 글이 없습니다.</span>
            </template>
          </div>
        </article>
      </div>

      <div class="btn-bottom-wrapper" v-if="noticeinfo.can_modify">
        <b-button
          size="xl"
          variant="outline-secondary"
          aria-label="목록"
          @click="
            $router.push({
              path: '/customer/noticelist/' + searchData.category,
            })
          "
          >목록</b-button
        >
        <b-button
          size="xl"
          variant="secondary"
          aria-label="삭제"
          @click="noticeDelete"
          >삭제</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          aria-label="수정"
          @click="
            $router.push({
              name: 'NoticeWrite',
              params: { noticeno: $route.params.noticeno },
              query: { category: searchData.category },
            })
          "
        >
          수정
        </b-button>
      </div>

      <div class="btn-bottom-wrapper" v-if="noticeinfo.can_reply">
        <b-button
          size="xl"
          variant="outline-primary"
          aria-label="목록"
          @click="
            $router.push({
              path: '/customer/noticelist/' + searchData.category,
            })
          "
          >목록</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          @click="
            $router.push({
              name: 'NoticeReplyWrite',
              params: { noticeno: $route.params.noticeno },
              query: { category: searchData.category },
            })
          "
          >{{
            noticeinfo.status === "완료" ? "답변수정" : "답변등록"
          }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/customer";
import "@/filter/common";

export default {
  name: "NoticeView",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "fit_as_title",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        category:
          this.$route.query.category ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].category) ||
          "",
      },

      brandno:
        (this.$store.state.admin && this.$store.state.admin.brandno) || "0",
      noticeno: this.$route.params.noticeno,
      noticeinfo: { content: "" },
      next_post: {},
      prev_post: {},

      mainbrandno: 0,
    };
  },
  methods: {
    get_custormerview() {
      if (this.mainbrandno) {
        this.brandno = this.mainbrandno;
      }
      if (this.noticeno) {
        ax.get_custormerview(
          this.noticeno,
          this.searchData,
          this.brandno,
          (flag, data) => {
            console.log(flag, data);
            if (flag) {
              this.noticeinfo = data.noticeinfo;
              this.next_post = data.next_post;
              this.prev_post = data.prev_post;

              if (sessionStorage.getItem("isAdmin") == "1") {
                if (
                  data.noticeinfo.brandno &&
                  this.mainbrandno != data.noticeinfo.brandno
                ) {
                  this.$store.commit("setBrandno", data.noticeinfo.brandno);
                  // window.location.reload(true);
                }
                this.brandno = data.noticeinfo.brandno;
              }
            } else {
              alert(data);
            }
          }
        );
      }
    },
    noticeDelete() {
      ax.delete_custormerdelete(this.noticeno, (flag, data) => {
        if (flag) {
          alert(data.message);
          // this.$notify({
          //   group: "alert",
          //   type: "success",
          //   text: data.message,
          //   speed: 1000,
          // });
          this.$router.push({
            path: "/customer/noticelist/" + this.searchData.category,
          });
        } else {
          alert(data.message);
        }
      });
    },
  },
  created() {
    this.get_custormerview();
  },
  watch: {
    "$route.params": function () {
      this.noticeno = parseInt(this.$route.params.noticeno);
      this.get_custormerview();
    },
  },
};
</script>
