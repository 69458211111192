<template>
  <div class="container-fluid">
    <location />
    <div class="page">
      <div class="specs card">
        <div v-b-toggle.collapse-spec class="collapse-content">
          <div class="me-3">
            <p class="mb-2 goods-kind">
              <span class="brand-name">{{
                fittinginfo.goodsinfo.fit_brand_nm
              }}</span
              >{{ fittinginfo.goodsinfo.goodstype | get_club_type }}({{
                fittinginfo.goodsinfo.clubtype_code_name
              }})
            </p>
            <p class="goods-name">{{ fittinginfo.goodsinfo.goodsname }}</p>
          </div>
        </div>
        <b-collapse visible id="collapse-spec" class="current-spec border-top">
          <b-card class="border-0" body-class="pt-3">
            <div class="row g-3 g-lg-4">
              <div class="col-lg-7">
                <h6 class="mb-2 small">클럽 스펙</h6>
                <div class="row g-0 table-type header-col border-1">
                  <div class="col">로프트</div>
                  <div class="col">
                    {{ fittinginfo.goodsinfo.goodsloft | toFixed }}&deg;
                  </div>
                  <div class="col">라이</div>
                  <div class="col">
                    {{ fittinginfo.goodsinfo.goodslie | toFixed }}&deg;
                  </div>
                  <div class="col">솔</div>
                  <div class="col">
                    {{ fittinginfo.goodsinfo.goodssole | toFixed }}&deg;
                  </div>
                  <div class="col">FP</div>
                  <div class="col">
                    {{ fittinginfo.goodsinfo.goodsfp | toFixed }}mm
                  </div>
                  <div class="col">길이</div>
                  <div class="col">
                    {{ fittinginfo.goodsinfo.goodslength | toFixed }}inch
                  </div>
                  <div class="col">총무게</div>
                  <div class="col">
                    {{ fittinginfo.goodsinfo.goodsweight | toFixed }}g
                  </div>
                </div>
              </div>

              <div class="col-lg-5">
                <div class="shaft-info">
                  <h6 class="mb-2 small">샤프트 스펙</h6>
                  <div
                    class="row g-0 table-type header-col border-1 border-bottom-0"
                  >
                    <!-- <div class="col">품명</div> -->
                    <div class="col flex-fill bg-white">
                      {{ fittinginfo.goodsinfo.shaftname || "-" }}
                    </div>
                  </div>
                  <div class="row g-0 table-type header-col border-top">
                    <div class="col">재질</div>
                    <div class="col flex-fill">
                      {{
                        fittinginfo.goodsinfo.shaftmaterial | get_shaft_material
                      }}
                    </div>
                    <div class="col">강도</div>
                    <div class="col flex-fill align-self-center">
                      {{ fittinginfo.goodsinfo.shaftflextype | get_balance }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
      <!-- //스펙 -->

      <div class="d-flex justify-content-between align-items-center my-2">
        <div id="popover-container">
          <b-button id="basic-info" href="#" tabindex="0" class="btn-text"
            ><span class="material-icons-outlined me-1">info</span
            >등록정보</b-button
          >
          <b-popover
            target="basic-info"
            container="popover-container"
            placement="bottomleft"
            custom-class="popover-reg-info"
          >          
            <ul class="list-unstyled fs-14">
              <li>
                <strong>닉네임</strong>
                <span class="text-secondary pointer"
                  ><MemberInfo
                    :nickname="fittinginfo.swingdetailinfo.nickname"
                    :memberno="fittinginfo.swingdetailinfo.memberno"
                  />
                  <span class="material-icons-outlined fs-6">help_outline</span>
                </span>
              </li>
              <li>
                <strong>매장명</strong
                ><span class="text-secondary">{{
                  fittinginfo.swingdetailinfo.shopname
                }}</span>
              </li>
              <li>
                <strong>신청일</strong
                ><span class="text-date">{{
                  fittinginfo.swingdetailinfo.regdate
                }}</span>
              </li>
            </ul>
          </b-popover>
        </div>

        <b-button class="btn-text" @click="$bvModal.show('modal-shot-list')">
          <span class="material-icons-outlined me-1">list_alt</span>샷 목록
        </b-button>
        <b-modal
          id="modal-shot-list"
          size="lg"
          scrollable
          hide-footer
          modal-class="modal-slideup"
          body-class="pt-3"
          footer-class="btn-bottom-wrapper"
        >
          <template #modal-header="{ close }">
            <h5 class="modal-title">샷 목록</h5>
            <b-button variant="close" @click="close()"></b-button>
          </template>

          <template>
            <div class="row g-1 row-cols-2 row-cols-sm-6 mb-3">
              <div class="col">
                <select
                  aria-label="검색조건선택"
                  v-model="searchData.goodstype"
                >
                  <option value="">전체</option>
                  <option
                    v-for="val in swingkeyset"
                    :key="'goodstype' + val"
                    :value="val"
                  >
                    {{ val | get_club_type }}
                  </option>
                </select>
              </div>
              <div class="col">
                <select aria-label="샷 선택" v-model="searchData.no">
                  <option value="0">샷 선택</option>
                  <option
                    v-for="val in items.swinginfo.filter((v) =>
                      searchData.goodstype
                        ? v.goodstype == searchData.goodstype
                        : true
                    )"
                    :key="'fit_binary_pk' + val.fit_binary_pk"
                    :value="val.fit_binary_pk"
                  >
                    #{{ val.no }}
                  </option>
                </select>
              </div>
            </div>

            <div class="accordion list-type" role="tablist">
              <template
                v-for="val in Array.from(swingkeyset).filter((v) =>
                  searchData.goodstype ? v == searchData.goodstype : true
                )"
              >
                <template
                  v-for="(item, idx) in items.swinginfo.filter(
                    (v) => v.goodstype == val
                  )"
                >
                  <b-card no-body class="rounded-0" :key="item.fit_binary_pk">
                    <b-card-header header-tag="header" role="tab">
                      <div
                        v-b-toggle="'shot-no' + item.fit_binary_pk"
                        class="collapse-content"
                        @click="
                          get_fittingswingview(fittingno, item.fit_binary_pk)
                        "
                      >
                        <div class="row g-2 row-cols-auto me-2 fs-14">
                          <div class="d-flex col align-items-center">
                            <span
                              class="video-state me-1"
                              :class="{
                                'has-video': item.fit_swing_video_name,
                              }"
                            >
                            </span>
                            <strong class="text-black">#{{ item.no }}</strong>
                          </div>
                          <div class="col">
                            <span
                              class="material-icons pe-1 fs-6"
                              :class="flagColor[item.goodstype]"
                              >flag</span
                            >
                            <span class="goods-kind">{{
                              item.goodstype | get_club_code
                            }}</span>
                          </div>
                          <div class="col text-nowrap">
                            <strong class="text-black">거리</strong>
                            {{ item.distance | toFixed }}m
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <b-collapse
                      :id="'shot-no' + item.fit_binary_pk"
                      :visible="
                        searchData.no
                          ? searchData.no == item.fit_binary_pk
                          : idx == 0
                      "
                      accordion="shot-list-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="p-0">
                        <div
                          class="row g-0 table-type header-col size-sm row-cols-4 row-cols-sm-6"
                        >
                          <div class="col">캐리</div>
                          <div class="col">{{ item.carry | toFixed }}m</div>
                          <div class="col">볼속도</div>
                          <div class="col">
                            {{ item.ballspeed | toFixed }}m/s
                          </div>
                          <div class="col">볼방향</div>
                          <div class="col">
                            {{ item.balldirection | toFixed }}&deg;
                          </div>
                          <div class="col">탈출각</div>
                          <div class="col">
                            {{ item.launchangle | toFixed }}&deg;
                          </div>
                          <div class="col">백스핀</div>
                          <div class="col">
                            {{ item.backspin | toFixed }}rpm
                          </div>
                          <div class="col">사이드스핀</div>
                          <div class="col">
                            {{ item.sidespin | toFixed }}rpm
                          </div>
                          <div class="col">이격거리</div>
                          <div class="col">
                            {{ item.seperationdistance | toFixed }}m
                          </div>
                          <div class="col">수직최고점</div>
                          <div class="col">
                            {{ item.peakvertical | toFixed }}m
                          </div>
                          <div class="col">체공시간</div>
                          <div class="col">{{ item.duration | toFixed }}s</div>
                          <div class="col">수평최고점</div>
                          <div class="col">
                            {{ item.peakhorizontal | toFixed }}m
                          </div>
                          <div class="col">헤드속도</div>
                          <div class="col">
                            {{ item.headspeed | toFixed }}m/s
                          </div>
                          <div class="col">미트율</div>
                          <div class="col">
                            {{ item.smashfactor | toFixed }}%
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </template>
                <b-card no-body class="rounded-0" :key="'avg' + val">
                  <b-card-header header-tag="header" role="tab">
                    <div
                      v-b-toggle="'shot-avg' + val"
                      class="collapse-content bg-light"
                    >
                      <div class="row g-2 row-cols-auto me-2 fs-14">
                        <div class="d-flex col align-items-center">
                          <strong class="text-black"
                            >{{ val | get_club_code }} 평균</strong
                          >
                        </div>
                        <div class="vr mx-2 p-0"></div>
                        <div class="col text-nowrap">
                          <strong class="text-black">거리</strong>
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.distance)) || [0]
                            ) | toFixed
                          }}
                          m
                        </div>
                      </div>
                    </div>
                  </b-card-header>
                  <b-collapse
                    :id="'shot-avg' + val"
                    accordion="shot-list-accordion"
                    role="tabpanel"
                  >
                    <b-card-body class="p-0">
                      <div
                        class="row g-0 table-type header-col size-sm row-cols-4 row-cols-sm-6"
                      >
                        <div class="col">캐리</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.carry)) || [0]
                            ) | toFixed
                          }}
                          m
                        </div>
                        <div class="col">볼속도</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.ballspeed)) || [0]
                            ) | toFixed
                          }}m/s
                        </div>
                        <div class="col">볼방향</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.balldirection)) || [0]
                            ) | toFixed
                          }}&deg;
                        </div>
                        <div class="col">탈출각</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.launchangle)) || [0]
                            ) | toFixed
                          }}&deg;
                        </div>
                        <div class="col">백스핀</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.backspin)) || [0]
                            ) | toFixed
                          }}rpm
                        </div>
                        <div class="col">사이드스핀</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.sidespin)) || [0]
                            ) | toFixed
                          }}rpm
                        </div>
                        <div class="col">이격거리</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.seperationdistance)) || [0]
                            ) | toFixed
                          }}m
                        </div>
                        <div class="col">수직최고점</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.peakvertical)) || [0]
                            ) | toFixed
                          }}m
                        </div>
                        <div class="col">체공시간</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.duration)) || [0]
                            ) | toFixed
                          }}s
                        </div>
                        <div class="col">수평최고점</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.peakhorizontal)) || [0]
                            ) | toFixed
                          }}m
                        </div>
                        <div class="col">헤드속도</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.headspeed)) || [0]
                            ) | toFixed
                          }}m/s
                        </div>
                        <div class="col">미트율</div>
                        <div class="col">
                          {{
                            average(
                              (items.swinginfo.filter(
                                (v) => v.goodstype == val
                              ) &&
                                items.swinginfo
                                  .filter((v) => v.goodstype == val)
                                  .map((v) => v.smashfactor)) || [0]
                            ) | toFixed
                          }}%
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </template>
            </div>
            <!-- //accordion -->
          </template> </b-modal
        ><!-- // 팝업, 샷 목록 -->
      </div>
      <!-- //등록정보, 샷 목록 -->

      <section class="swing-anls mt-0">
        <div class="row">
          <div class="col-lg-8 flex-column">
            <div class="item h-100 d-flex flex-column">
              <video-player
                class="video-player vjs-custom-skin"
                :playsinline="true"
                :options="playerOptions"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @ended="onPlayerEnded($event)"
                @ready="playerReadied($event)"
              >
              </video-player>
              <div class="row swing-seq g-0">
                <div
                  class="col"
                  :class="{ active: videoControl == 'address' }"
                  @click="
                    setPlayerframe(
                      fittinginfo.swingdetailinfo.fit_address,
                      'address'
                    )
                  "
                >
                  <span class="d-none d-sm-inline-block">address</span>
                  <img
                    class="d-sm-none d-inline-block img-fluid"
                    src="/img/swing-seq-step1.svg"
                  />
                </div>
                <div
                  class="col"
                  :class="{ active: videoControl == 'backswing' }"
                  @click="
                    setPlayerframe(
                      fittinginfo.swingdetailinfo.fit_takeback,
                      'backswing'
                    )
                  "
                >
                  <span class="d-none d-sm-inline-block">backswing</span>
                  <img
                    class="d-sm-none d-inline-block img-fluid"
                    src="/img/swing-seq-step2.svg"
                  />
                </div>
                <div
                  class="col"
                  :class="{ active: videoControl == 'topswing' }"
                  @click="
                    setPlayerframe(
                      fittinginfo.swingdetailinfo.fit_top_swing,
                      'topswing'
                    )
                  "
                >
                  <span class="d-none d-sm-inline-block">topswing</span>
                  <img
                    class="d-sm-none d-inline-block img-fluid"
                    src="/img/swing-seq-step3.svg"
                  />
                </div>
                <div
                  class="col"
                  :class="{ active: videoControl == 'downswing' }"
                  @click="
                    setPlayerframe(
                      fittinginfo.swingdetailinfo.fit_down_swing,
                      'downswing'
                    )
                  "
                >
                  <span class="d-none d-sm-inline-block">downswing</span>
                  <img
                    class="d-sm-none d-inline-block img-fluid"
                    src="/img/swing-seq-step4.svg"
                  />
                </div>
                <div
                  class="col"
                  :class="{ active: videoControl == 'impact' }"
                  @click="
                    setPlayerframe(
                      fittinginfo.swingdetailinfo.fit_impact,
                      'impact'
                    )
                  "
                >
                  <span class="d-none d-sm-inline-block">impact</span>
                  <img
                    class="d-sm-none d-inline-block img-fluid"
                    src="/img/swing-seq-step5.svg"
                  />
                </div>
                <div
                  class="col"
                  :class="{ active: videoControl == 'follow' }"
                  @click="
                    setPlayerframe(
                      fittinginfo.swingdetailinfo.fit_follow,
                      'follow'
                    )
                  "
                >
                  <span class="d-none d-sm-inline-block">follow-through</span>
                  <img
                    class="d-sm-none d-inline-block img-fluid"
                    src="/img/swing-seq-step6.svg"
                  />
                </div>
                <div
                  class="col"
                  :class="{ active: videoControl == 'finish' }"
                  @click="
                    setPlayerframe(
                      fittinginfo.swingdetailinfo.fit_finish,
                      'finish'
                    )
                  "
                >
                  <span class="d-none d-sm-inline-block">finish</span>
                  <img
                    class="d-sm-none d-inline-block img-fluid"
                    src="/img/swing-seq-step7.svg"
                  />
                </div>
              </div>
              <!-- golf swing sequence : 7가지 구분 동작 -->

              <div class="swing-wt g-0 item h-100">
                <div class="wt-info">
                  <div class="wt-value">
                    <!-- 좌우전후 -->
                    <div
                      class="position-absolute top-0 start-50 translate-middle-x text-green"
                    >
                      L
                      <input ref="chartDataL" value="0" />
                    </div>
                    <div
                      class="position-absolute bottom-0 start-50 translate-middle-x text-green"
                    >
                      <input ref="chartDataR" value="0" />
                      R
                    </div>
                    <div
                      class="position-absolute top-50 end-0 translate-middle-y text-orange"
                    >
                      F
                      <input ref="chartDataF" value="0" />
                    </div>
                    <div
                      class="position-absolute top-50 start-0 translate-middle-y text-orange"
                    >
                      B
                      <input ref="chartDataB" value="180" />
                    </div>
                  </div>

                  <LineChartGenerator
                    class="wt-graph"
                    :chart-options="chartConfig.options"
                    :chart-data="chartConfig.data"
                  />
                </div>
              </div>
              <!-- 체중그래프 -->
            </div>
          </div>

          <div class="col-lg-4 flex-column flex-md-row flex-lg-column">
            <LineChartGenerator
              class="col item"
              :chart-options="{
                ...chartConfigSide.options,
                onClick: (point, event) => {
                  $bvModal.show('modal-traj-side');
                },
                pointRadius: function (context) {
                  if (context.dataIndex == context.dataset.data.length - 1) {
                    return 5;
                  } else {
                    return 0;
                  }
                },
              }"
              :chart-data="chartConfigSide.data"
              :height="183"
            />

            <LineChartGenerator
              class="col item mx-md-2 mx-lg-0 my-2 my-md-0 my-lg-2"
              :chart-options="{
                ...chartConfigTop.options,
                onClick: (point, event) => {
                  $bvModal.show('modal-traj-top');
                },
                pointRadius: function (context) {
                  if (context.dataIndex == context.dataset.data.length - 1) {
                    return 5;
                  } else {
                    return 0;
                  }
                },
              }"
              :chart-data="chartConfigTop.data"
              :height="183"
            />

            <div class="col item" @click="$bvModal.show('modal-traj-club')">
              <img
                :src="fittinginfo.swingdetailinfo.fit_club_trajectory_path"
                class="img-fluid mh-100"
              />
            </div>
          </div>
        </div>
      </section>
      <!-- //골프 스윙분석 -->

      <b-modal
        id="modal-traj-side"
        size="lg"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">궤적 측면 뷰</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <LineChartGenerator
            :chart-options="chartConfigSide.options"
            :chart-data="chartConfigSide.data"
          />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            aria-label="닫기"
            @click="cancel()"
          >
            닫기
          </b-button>
          <b-button size="md" variant="secondary" aria-label="인쇄">
            인쇄
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-traj-top"
        size="lg"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">궤적 상단 뷰</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <LineChartGenerator
            :chart-options="chartConfigTop.options"
            :chart-data="chartConfigTop.data"
          />
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            aria-label="닫기"
            @click="cancel()"
          >
            닫기
          </b-button>
          <b-button size="md" variant="secondary" aria-label="인쇄">
            인쇄
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-traj-club"
        size="lg"
        centered
        scrollable
        footer-class="btn-bottom-wrapper"
      >
        <template #modal-header="{ close }">
          <h5 class="modal-title">클럽 패스</h5>
          <b-button variant="close" @click="close()"></b-button>
        </template>

        <template>
          <div class="bg-light">
            <img
              :src="fittinginfo.swingdetailinfo.fit_club_trajectory_path"
              class="img-fluid mh-100"
            />
          </div>
        </template>

        <template #modal-footer="{ cancel }">
          <b-button
            size="md"
            variant="outline-secondary"
            aria-label="닫기"
            @click="cancel()"
          >
            닫기
          </b-button>
          <b-button size="md" variant="secondary" aria-label="인쇄">
            인쇄
          </b-button>
        </template>
      </b-modal>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-secondary"
          aria-label="목록"
          @click="
            $router.push({
              name: 'FittingList',
              query: { goodstype: $route.query.goodstype },
            })
          "
          >목록</b-button
        >
        <b-button
          size="xl"
          variant="secondary"
          aria-label="샷 목록"
          @click="$bvModal.show('modal-shot-list')"
          >샷 목록</b-button
        >
        <b-button
          size="xl"
          variant="primary"
          aria-label="피팅내역"
          v-if="fitinfo.state_cd != '0'"
          @click="
            $router.push({
              name: 'FittingAnswerView',
              params: {
                fitreqno: fitinfo.fit_req_pk,
              },
              query: { goodstype: $route.query.goodstype },
            })
          "
          >피팅내역</b-button
        >

        <b-button
          size="xl"
          variant="primary"
          aria-label="피팅등록"
          v-if="fitinfo.state_cd == '0'"
          @click="
            $router.push({
              name: 'FittingAnswerWrite',
              params: {
                fitreqno: fitinfo.fit_req_pk,
              },
              query: { goodstype: $route.query.goodstype },
            })
          "
          >피팅등록</b-button
        >
      </div>
      <!-- //btn-bottom-wrapper -->
    </div>
    <!-- //page -->
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/fitting";

import "@/filter/common";
// import VideoPlayer from "vue-video-player";
// import videojs from "video.js";

import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

export default {
  name: "FittingSwingList",
  components: {
    location,
    LineChartGenerator,
  },
  data() {
    return {
      player: null,
      wordLR: [],
      chartDataLR: [],
      chartDataFB: [],
      chartConfig: { options: {}, data: {} },
      flagColor: [
        "text-blue",
        "text-indigo",
        "text-purple",
        "text-pink",
        "text-red",
        "text-orange",
        "text-yellow",
      ],
      flagColorCode: [
        "#3577f1",
        "#0033a1",
        "#552bb6",
        "#ce2f92",
        "#ef2b49",
        "#ff7519",
        "#ffc107",
      ],
      chartDataSide: [],
      chartConfigSide: {
        options: {
          interaction: {
            intersect: false,
          },
          plugins: {
            tooltip: { enabled: false },
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              type: "linear",
              suggestedMax: 300,
            },
            y: {
              display: true,
              min: 0,
            },
          },
          pointRadius: 0,
          pointBorderColor: "#D3687F", //포인터 보더 색상
          responsive: true,
          maintainAspectRatio: false,
        },
        data: { datasets: [] },
      },
      chartDataTop: [],
      chartConfigTop: { options: {}, data: { datasets: [] } },

      absVal: 0,
      totalDuration: 10000,
      delayBetweenPoints: 0,

      animation: {},
      previousY: (ctx) =>
        ctx.index === 0
          ? ctx.chart.scales.y.getPixelForValue(100)
          : ctx.chart
              .getDatasetMeta(ctx.datasetIndex)
              .data[ctx.index - 1].getProps(["y"], true).y,
      playerOptions: {
        inactivityTimeout: 0,
        bigPlayButton: false,
        playbackRates: [], //[0.5, 1.0, 1.5, 2.0]
        autoplay: true, //  true,           。
        muted: false, //              。
        loop: false, //           。
        preload: "auto", //       <video>                 。auto         ,        （       ）
        language: "zh-CN",
        aspectRatio: "16:9", //           ，                 。          -           （  "16:9" "4:3"）
        fluid: true, //  true ，Video.js player       。    ，             。
        sources: [],
        poster: "", //
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "        ，     ", //    Video.js               。
        controlBar: {
          timeDivider: true, //
          durationDisplay: true, //
          remainingTimeDisplay: true, //
          fullscreenToggle: true, //
        },
      },

      searchData: {
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "fit_as_title",
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        category:
          this.$route.query.category ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].category) ||
          "",
        swingno: 0,
        goodstype: "",
        no: "0",
        fit_shotdata_pk: 0,
      },
      fittingno: this.$route.params.fittingno,

      items: { swinginfo: [], totalswinginfo: {} },
      fitinfo: {},
      swingkeyset: new Set(),
      fittinginfo: { goodsinfo: {}, swingdetailinfo: {} },
      memberinfo: { basicinfo: {} },
      videoControl: "",

      context: null,
      animationFlag: false,
    };
  },

  methods: {
    get_fittingswinglist() {
      ax.get_fittingswinglist(this.fittingno, this.searchData, (flag, data) => {
        if (flag) {
          this.items = data.fittingswinglist;
          this.fitinfo = data.fitinfo;
          if (data.fittingswinglist.swinginfo.length) {
            this.searchData.fit_binary_pk =
              data.fittingswinglist.swinginfo[0].fit_binary_pk || 0;

            this.get_fittingswingview(
              this.fittingno,
              this.searchData.fit_binary_pk
            );

            data.fittingswinglist.swinginfo.forEach((v) => {
              this.swingkeyset.add(v.goodstype);

              const words = v.balltrajectory.split(",");
              const words_ = words.map((v) => v.split("|"));
              let datatop_ = [];
              let dataside_ = [];
              words_.forEach((v, i) => {
                if (this.absVal < Math.abs(parseInt(v[0]) / 10))
                  this.absVal = Math.abs(parseInt(v[0]) / 10);

                datatop_.push({
                  x: Math.round(parseInt(v[2]) / 10),
                  y: parseInt(v[0]) / 10,
                });
                dataside_.push({
                  x: Math.round(parseInt(v[2]) / 10),
                  y: parseInt(v[1]) / 10,
                });
              });

              this.chartDataTop.push({
                data: datatop_,
                fit_shotdata_pk: v.fit_shotdata_pk,
                goodstype: v.goodstype,
              });

              this.chartDataSide.push({
                data: dataside_,
                fit_shotdata_pk: v.fit_shotdata_pk,
                goodstype: v.goodstype,
              });
            });

            this.chartConfigTop.options = {
              interaction: {
                intersect: false,
              },
              plugins: {
                tooltip: { enabled: false },

                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  type: "linear",
                  suggestedMax: 300,
                },
                y: {
                  display: true,
                  suggestedMax: this.absVal,
                  suggestedMin: -this.absVal,
                },
              },
              pointRadius: 0,

              pointBorderColor: "#D3687F", //포인터 보더 색상
              responsive: true,
              maintainAspectRatio: false,
            };
          }
        } else {
          alert(data.message);
        }
      });
    },
    get_fittingswingview(fittingno, swingno) {
      let left_right = 0;
      let front_back = 0;
      let fit_oneshot_fk_ = 0;
      this.chartConfigTop.data.datasets = [];
      this.chartConfigSide.data.datasets = [];
      this.chartDataLR = [];
      this.chartDataFB = [];
      this.playerOptions = {
        ...this.playerOptions,
        sources: [],
      };

      ax.get_fittingswingview(fittingno, swingno, (flag, data) => {
        if (flag) {
          this.fittinginfo = data.fittinginfo;

          fit_oneshot_fk_ =
            (this.fittinginfo.swingdetailinfo &&
              this.fittinginfo.swingdetailinfo.fit_oneshot_fk) ||
            0;

          this.playerOptions = {
            ...this.playerOptions,
            sources: [
              {
                type: "video/mp4",
                src: this.fittinginfo.swingdetailinfo.fit_swing_video_path,
              },
            ],
          };

          this.chartDataTop.sort((a, b) => {
            return b.fit_shotdata_pk == fit_oneshot_fk_ ? 1 : -1;
          });

          this.chartDataSide.sort((a, b) => {
            return b.fit_shotdata_pk == fit_oneshot_fk_ ? 1 : -1;
          });

          this.chartDataTop.forEach((v) => {
            this.chartConfigTop.data.datasets.push({
              borderColor:
                v.fit_shotdata_pk == fit_oneshot_fk_ ? "#ff0000" : "#0000ff",
              borderWidth: 1,
              radius: 0,
              data: v.data,
            });
          });
          this.chartDataSide.forEach((v) => {
            this.chartConfigSide.data.datasets.push({
              borderColor:
                v.fit_shotdata_pk == fit_oneshot_fk_ ? "#ff0000" : "#0000ff",
              borderWidth: 1,
              radius: 0,
              data: v.data,
            });
          });
          let words = [];
          if (this.fittinginfo.swingdetailinfo.fit_weight_info) {
            console.log("11111111");
            words = this.fittinginfo.swingdetailinfo.fit_weight_info.split(",");
          } else {
            console.log("2222222");

            for (let i = 0; i < 600; i++) {
              words.push("0|0|0|0");
            }
          }
          console.log("words", words);
          this.wordLR = words.map((v) => v.split("|"));
          console.log("fit_oneshot_fk_", this.wordLR);
          this.wordLR.forEach((v, i) => {
            left_right = 0;
            front_back = 0;

            left_right =
              (parseInt(v[0]) / (parseInt(v[0]) + parseInt(v[2]))) * 100;
            left_right = left_right < 0 || isNaN(left_right) ? 0 : left_right;

            front_back =
              (parseInt(v[2]) / (parseInt(v[1]) + parseInt(v[3]))) * 100;

            front_back = front_back < 0 || isNaN(front_back) ? 0 : front_back;

            this.chartDataLR.push({ x: i, y: left_right });

            this.chartDataFB.push({ x: i, y: front_back });
          });

          this.delayBetweenPoints =
            (this.totalDuration / this.chartDataLR.length) * 0.3;
          const _delayBetweenPoints = 8.4;
          this.animation = {
            onComplete: (context) => {
              // console.log("animation finished");

              this.context = context;

              // context.chart.update();
            },
            x: {
              type: "number",
              easing: "linear",

              duration: _delayBetweenPoints,

              from: NaN, // the point is initially skipped\
              // loop: true,

              delay(ctx) {
                if (ctx.type !== "data" || ctx.xStarted) {
                  return 0;
                }
                ctx.xStarted = true;

                return ctx.index * _delayBetweenPoints;
              },
            },
            y: {
              type: "number",
              easing: "linear",

              duration: _delayBetweenPoints,
              from: this.previousY,
              // loop: true,

              delay(ctx) {
                if (ctx.type !== "data" || ctx.yStarted) {
                  return 0;
                }
                ctx.yStarted = true;
                return ctx.index * _delayBetweenPoints;
              },
            },
          };
          this.chartConfig = {
            data: {
              datasets: [
                {
                  borderColor: "#00b34a",
                  borderWidth: 1,
                  radius: 0,

                  data: this.chartDataLR,
                },
                {
                  borderColor: "#ff7519",
                  borderWidth: 1,
                  radius: 0,

                  data: this.chartDataFB,
                },
              ],
            },
            options: {
              animation: this.animation,

              onClick: (point, event) => {
                this.player.currentTime(
                  event.pop().index /
                    (this.fittinginfo.swingdetailinfo.fit_total_frames /
                      this.fittinginfo.swingdetailinfo.fit_play_time)
                );
                this.player.pause();

                console.log("event : ", event);
                console.log("point : ", point);
              },
              interaction: {
                intersect: false,
              },
              plugins: {
                tooltip: {
                  // enabled: false,
                  callbacks: {
                    label: (context) => {
                      let label = "";

                      // if (label) {
                      //   label += ": ";
                      // }
                      if (context.dataIndex !== null) {
                        // console.log(this.wordLR[context.dataIndex]);

                        let left_ = 0;
                        let right_ = 0;
                        let front_ = 0;
                        let back_ = 0;

                        left_ =
                          (parseInt(this.wordLR[context.dataIndex][0]) /
                            (parseInt(this.wordLR[context.dataIndex][0]) +
                              parseInt(this.wordLR[context.dataIndex][2]))) *
                          100;
                        left_ = left_ < 0 || isNaN(left_) ? 0 : left_;
                        left_ = left_ > 100 ? 100 : left_;

                        right_ =
                          (parseInt(this.wordLR[context.dataIndex][2]) /
                            (parseInt(this.wordLR[context.dataIndex][0]) +
                              parseInt(this.wordLR[context.dataIndex][2]))) *
                          100;
                        right_ = right_ < 0 || isNaN(right_) ? 0 : right_;
                        right_ = right_ > 100 ? 100 : right_;

                        front_ =
                          (parseInt(this.wordLR[context.dataIndex][1]) /
                            (parseInt(this.wordLR[context.dataIndex][1]) +
                              parseInt(this.wordLR[context.dataIndex][3]))) *
                          100;

                        front_ = front_ < 0 || isNaN(front_) ? 0 : front_;
                        front_ = front_ > 100 ? 100 : front_;

                        back_ =
                          (parseInt(this.wordLR[context.dataIndex][3]) /
                            (parseInt(this.wordLR[context.dataIndex][1]) +
                              parseInt(this.wordLR[context.dataIndex][3]))) *
                          100;

                        back_ = back_ < 0 || isNaN(back_) ? 0 : back_;
                        back_ = back_ > 100 ? 100 : back_;

                        if (
                          this.fittinginfo.swingdetailinfo.fit_weight_type ==
                          "1"
                        ) {
                          label = [
                            "L: " +
                              Math.round(left_) +
                              " R: " +
                              Math.round(right_),
                            "F: " +
                              Math.round(front_) +
                              " B: " +
                              Math.round(back_),
                          ];
                        } else {
                          label = [
                            "L: " +
                              Math.round(left_) +
                              " R: " +
                              Math.round(right_),
                          ];
                        }
                      }

                      return label;
                    },
                  },
                },
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  display: false,
                  type: "linear",
                },
                y: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            },
          };

          this.wordLR.forEach((v, i) => {
            setTimeout(() => {
              let left = 0;
              let right = 0;
              let front = 0;
              let back = 0;

              left = (parseInt(v[0]) / (parseInt(v[0]) + parseInt(v[2]))) * 100;
              left = left < 0 || isNaN(left) ? 0 : left;
              left = left > 100 ? 100 : left;

              right =
                (parseInt(v[2]) / (parseInt(v[0]) + parseInt(v[2]))) * 100;
              right = right < 0 || isNaN(right) ? 0 : right;
              right = right > 100 ? 100 : right;

              front =
                (parseInt(v[1]) / (parseInt(v[1]) + parseInt(v[3]))) * 100;

              front = front < 0 || isNaN(front) ? 0 : front;
              front = front > 100 ? 100 : front;

              back = (parseInt(v[3]) / (parseInt(v[1]) + parseInt(v[3]))) * 100;

              back = back < 0 || isNaN(back) ? 0 : back;
              back = back > 100 ? 100 : back;

              this.$refs.chartDataL.value = Math.trunc(left);
              this.$refs.chartDataR.value = Math.trunc(right);
              this.$refs.chartDataF.value = Math.trunc(front);
              this.$refs.chartDataB.value = Math.trunc(back);

              // display(name);
            }, i * 7);
          });

          console.log(this.fittinginfo);
        } else {
          alert(data.message);
        }
      });
    },

    onPlayerPlay(player) {
      console.log("onPlayerPlay");
      console.log(player);
      if (this.animationFlag && this.context) {
        this.animationFlag = false;
        this.context.chart.update();

        this.wordLR.forEach((v, i) => {
          setTimeout(() => {
            let left = 0;
            let right = 0;
            let front = 0;
            let back = 0;

            left = (parseInt(v[0]) / (parseInt(v[0]) + parseInt(v[2]))) * 100;
            left = left < 0 || isNaN(left) ? 0 : left;
            left = left > 100 ? 100 : left;

            right = (parseInt(v[2]) / (parseInt(v[0]) + parseInt(v[2]))) * 100;
            right = right < 0 || isNaN(right) ? 0 : right;
            right = right > 100 ? 100 : right;

            front = (parseInt(v[1]) / (parseInt(v[1]) + parseInt(v[3]))) * 100;

            front = front < 0 || isNaN(front) ? 0 : front;
            front = front > 100 ? 100 : front;

            back = (parseInt(v[3]) / (parseInt(v[1]) + parseInt(v[3]))) * 100;

            back = back < 0 || isNaN(back) ? 0 : back;
            back = back > 100 ? 100 : back;

            this.$refs.chartDataL.value = Math.trunc(left);
            this.$refs.chartDataR.value = Math.trunc(right);
            this.$refs.chartDataF.value = Math.trunc(front);
            this.$refs.chartDataB.value = Math.trunc(back);

            // display(name);
          }, i * 7);
        });
      }
      //   setTimeout(() => {
      //     let left = 0;
      //     let right = 0;
      //     let front = 0;
      //     let back = 0;

      //     left = (parseInt(v[0]) / (parseInt(v[0]) + parseInt(v[1]))) * 100;
      //     left = left < 0 || isNaN(left) ? 0 : left;
      //     left = left > 100 ? 100 : left;

      //     right = (parseInt(v[1]) / (parseInt(v[0]) + parseInt(v[1]))) * 100;
      //     right = right < 0 || isNaN(right) ? 0 : right;
      //     right = right > 100 ? 100 : right;

      //     front = (parseInt(v[2]) / (parseInt(v[2]) + parseInt(v[3]))) * 100;

      //     front = front < 0 || isNaN(front) ? 0 : front;
      //     front = front > 100 ? 100 : front;

      //     back = (parseInt(v[3]) / (parseInt(v[2]) + parseInt(v[3]))) * 100;

      //     back = back < 0 || isNaN(back) ? 0 : back;
      //     back = back > 100 ? 100 : back;

      //     this.$refs.chartDataL.value = Math.trunc(left);
      //     this.$refs.chartDataR.value = Math.trunc(right);
      //     this.$refs.chartDataF.value = Math.trunc(front);
      //     this.$refs.chartDataB.value = Math.trunc(back);

      //     // display(name);
      //   }, i * 7);
      // };
    },
    onPlayerPause(player) {
      console.log("onPlayerPause");
      console.log(player.cache_.currentTime);
    },
    setPlayerframe(frame, videoControl) {
      this.videoControl = videoControl;
      console.log("frame", frame);
      this.player.currentTime(
        frame /
          (this.fittinginfo.swingdetailinfo.fit_total_frames /
            this.fittinginfo.swingdetailinfo.fit_play_time)
      );
      this.player.pause();
    },
    onPlayerEnded(player) {
      console.log("onPlayerEnded");
      console.log(player.cache_.currentTime);
      console.log(player);

      // console.log("animation finished");
      const meta0 = this.context.chart.getDatasetMeta(0);
      const meta1 = this.context.chart.getDatasetMeta(1);
      for (let i = 0; i < this.chartDataFB.length; i++) {
        const ctx0 = meta0.controller.getContext(i);
        const ctx1 = meta1.controller.getContext(i);
        ctx0.xStarted = ctx0.yStarted = false;
        ctx1.xStarted = ctx1.yStarted = false;
      }

      this.animationFlag = true;
      // context.chart.update();
    },
    playerReadied(player) {
      console.log("playerReadied");
      console.log(player);
      this.player = player;
    },
    // onPlayerTimeupdate(player) {
    //   console.log("onPlayerTimeupdate");
    //   console.log(player);
    // },
    average(arr) {
      // console.log("arr", arr);
      return (
        arr.reduce((p, c) => parseFloat(p) + parseFloat(c), 0) / arr.length
      );
    },
  },
  created() {
    this.get_fittingswinglist();
  },

  watch: {
    "$route.params": function () {
      this.noticeno = parseInt(this.$route.params.noticeno);
      this.get_fittingswinglist();
    },
    "searchData.goodstype": function (newVal) {
      let item = null;
      item = this.items.swinginfo.filter((v) =>
        newVal ? v.goodstype == newVal : true
      );
      // this.searchData.no = item.length ? item[0].fit_binary_pk : "";
      this.searchData.no = "0";
    },
    "searchData.no": function (newVal) {
      if (newVal != "0") this.get_fittingswingview(this.fittingno, newVal);
    },
  },
};
</script>
